// @generated by protobuf-ts 2.8.1
// @generated from protobuf file "ProtobufAirTrafficSimulator.proto" (package "ProtobufAirTrafficSimulator", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp";
import { Duration } from "./google/protobuf/duration";
// This is not included in  the MadMan simulator. --> Might be interesting to request since
// we can then illustrate a variety of meshes if we know exactly the aircraft category.
// enum AircraftCategory {
//  OTHER_CATEGORY = 0;
//  LANDPLANE = 1;
//  SEAPLANE = 2;
//  AMPHIBIAN = 3;
//  GYROCOPTER = 4;
//  HELICOPTER = 5;
//  TILTWING = 6;
// }

// This is not included in the MadMan simulator.
// enum AircraftEnginesType {
//  OTHER_ENGINE_TYPE = 0;
//  JET = 1;
//  PISTON = 2;
//  TURBOPROP = 3;
//  ELECTRIC = 4;
// }

// ###################################################################################
// * Enumerations, end.                                                              |
//  ##################################################################################

// ###################################################################################
// * Messages, begin.                                                                |
//  ##################################################################################

/**
 *  Provides information of the flexibility in time for a milestone.
 *  Concept: A FlightMilestoneMessage about a future milestone comes with a time for when the milestone
 *  should happen. The PreferenceInterval gives additional information about the amount of flexibility
 *  for the milestone and how important it is for the source of the message that the milestone happens
 *  near the given time.
 *  This example define a preference interval where it is allowed to deviate as much as 5 minutes
 *  earlier to 10 minutes later. There is no cost associated to the event being earlier but there is a
 *  cost of 10 (cost units) per second the event is later than the expressed time.
 *  new PreferenceInterval(new TimeSpan(0, 5, 0), new TimeSpan(0, 10, 0), 0, 10);
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.PreferenceInterval
 */
export interface PreferenceInterval {
    /**
     * @generated from protobuf field: google.protobuf.Duration maxEarlier = 1;
     */
    maxEarlier?: Duration; // The maximum time the event is allowed to be shifted to be earlier than the expressed time.
    /**
     * Use TimeSpan.MaxValue if there is no limit.
     *
     * @generated from protobuf field: google.protobuf.Duration maxLater = 2;
     */
    maxLater?: Duration; // Use TimeSpan.MaxValue if there is no limit.
    /**
     * The maximum time the event is allowed to be shifted to be later than the expressed time.
     *
     * @generated from protobuf field: float costPerSecondEarlier = 3;
     */
    costPerSecondEarlier: number; // The cost per second that the event is earlier than the expressed time.
    /**
     * @generated from protobuf field: float costPerSecondLater = 4;
     */
    costPerSecondLater: number; // The cost per second that the event is later than the expressed time.
}
/**
 * A position given with a coordinate.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.Position4D
 */
export interface Position4D {
    /**
     * string networkId                = 1;  // The unique name of the network where the object is located.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1;
     */
    time?: Timestamp; // Inherited from Position.
    /**
     * The time for the position.
     *
     * @generated from protobuf field: float latitude = 2;
     */
    latitude: number; // GPS Position (WGS84).
    /**
     * @generated from protobuf field: float longitude = 3;
     */
    longitude: number;
    /**
     * @generated from protobuf field: float altitude = 4;
     */
    altitude: number; // Altitude (if known).
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.AvailabilitySchedule
 */
export interface AvailabilitySchedule {
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.AvailabilityIntervalsMessage availabilityIntervals = 1;
     */
    availabilityIntervals: AvailabilityIntervalsMessage[];
}
/**
 * A position given with a coordinate (X,Y,Z) where X and Y are coonrdinates in the plane and Z gives the altitude.
 * The Z-coordinate (altitude) is optional.
 * The unit is in meters relative to an origin coordinate defined for the network.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.Position4DCartesian
 */
export interface Position4DCartesian {
    /**
     * string networkId                = 1;  // The unique name of the network where the object is located.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1;
     */
    time?: Timestamp; // Inherited from Position.
    /**
     * The time for the position.
     *
     * @generated from protobuf field: float x = 2;
     */
    x: number;
    /**
     * @generated from protobuf field: float y = 3;
     */
    y: number;
    /**
     * @generated from protobuf field: float z = 4;
     */
    z: number;
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Milestone milestone = 5;
     */
    milestone: Milestone;
}
/**
 * A position addressing a unique object by its identifier.
 * The receiving system is assumed to know the position of the referenced object.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.PositionAtObject
 */
export interface PositionAtObject {
    /**
     * string networkId                =  1; // The unique name of the network where the object is located.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1;
     */
    time?: Timestamp; // Inherited from Position.
    /**
     * The time for the position.
     *
     * @generated from protobuf field: string objectId = 2;
     */
    objectId: string; // The id of the object.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.ObjectType objectType = 3;
     */
    objectType: ObjectType; // The type of object being identified.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Milestone milestone = 4;
     */
    milestone: Milestone; // The optional milestone relating to this position.
}
/**
 * A position along the trajectory
 * Abstract class
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.Position
 */
export interface Position {
    /**
     * @generated from protobuf oneof: position
     */
    position: {
        oneofKind: "position4D";
        /**
         * @generated from protobuf field: ProtobufAirTrafficSimulator.Position4D position4D = 1;
         */
        position4D: Position4D;
    } | {
        oneofKind: "position4DCartesian";
        /**
         * @generated from protobuf field: ProtobufAirTrafficSimulator.Position4DCartesian position4DCartesian = 2;
         */
        position4DCartesian: Position4DCartesian;
    } | {
        oneofKind: "positionAtObject";
        /**
         * @generated from protobuf field: ProtobufAirTrafficSimulator.PositionAtObject positionAtObject = 3;
         */
        positionAtObject: PositionAtObject;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.AirspaceVolumeReference
 */
export interface AirspaceVolumeReference {
    /**
     * @generated from protobuf field: string volumeId = 1;
     */
    volumeId: string; // The id of the volume it is referring to.
    /**
     * @generated from protobuf field: int32 bottomFlightLevel = 2;
     */
    bottomFlightLevel: number; // The bottom flight levelø, if not given then <see cref="FlightLevel.MinValue"/> is used.
    /**
     * @generated from protobuf field: int32 topFlightLevel = 3;
     */
    topFlightLevel: number; // The top flight level, if not given <see cref="FlightLevel.MaxValue"/> is used.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.TesselatedSectorSurface
 */
export interface TesselatedSectorSurface {
    /**
     * string surfaceType        = 1; // Can be either bottom, wall or top surface.
     *
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.Position corners = 1;
     */
    corners: Position[]; // Should be of type Position4D with time 00:00:00.
    /**
     * @generated from protobuf field: repeated int32 triangles = 2;
     */
    triangles: number[]; // Indices for the surface's triangles, should be 3 times the size of the corners list.
}
// A trajectory (path and time), e.g., for an aircraft.
// Concept: The trajectory consists of an ordered sequence of positions with a specified time and
// position. If the aircraft stays at a position for a period of time then the position should be
// repeated twice to specify both the entry time and the exit time. Positions (waypoints, runways)
// that can be identified through an unique id should be represented as an PositionObject while other
// positions needed to specify trajectory in sufficient detail will be represented as a Position4D.

/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.Trajectory
 */
export interface Trajectory {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string; // An optional id that make it possible to refer to this trajectory by name.
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.Position trajectory = 2;
     */
    trajectory: Position[]; // The complete list of positions.
}
/**
 * A message used to communicate to the UI interface (Unreal4) that all initialisation values have been transmitted.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.InitialisationCompleted
 */
export interface InitialisationCompleted {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp completionTime = 1;
     */
    completionTime?: Timestamp;
    /**
     * @generated from protobuf field: int32 numAirspaceConfigs = 2;
     */
    numAirspaceConfigs: number;
    /**
     * @generated from protobuf field: int32 numAirspaces = 3;
     */
    numAirspaces: number;
    /**
     * @generated from protobuf field: int32 numSectors = 4;
     */
    numSectors: number;
    /**
     * @generated from protobuf field: int32 numAirblocks = 5;
     */
    numAirblocks: number;
    /**
     * @generated from protobuf field: int32 numWaypoints = 6;
     */
    numWaypoints: number;
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.SimulatorTime
 */
export interface SimulatorTime {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp time = 1;
     */
    time?: Timestamp;
    /**
     * @generated from protobuf field: float speedFactor = 2;
     */
    speedFactor: number;
}
/**
 * A message used to inform the MadMan Library of position, direction and speed of the aircraft.
 * assigned to the flight.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.TargetReportMessage
 */
export interface TargetReportMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string vehicleId = 2;
     */
    vehicleId: string; // The ID of the vehicle in question.
    /**
     * @generated from protobuf field: google.protobuf.Timestamp time = 3;
     */
    time?: Timestamp; // The time for the observation.
    /**
     * @generated from protobuf field: float latitude = 4;
     */
    latitude: number; // GPS Position (WGS84).
    /**
     * @generated from protobuf field: float longitude = 5;
     */
    longitude: number; // -||-
    /**
     * @generated from protobuf field: float altitude = 6;
     */
    altitude: number; // -||- Altitude (if known)
    /**
     * @generated from protobuf field: float speed = 7;
     */
    speed: number; // Speed (m/s).
    /**
     * @generated from protobuf field: float bearing = 8;
     */
    bearing: number; // The bearing (degrees).
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timeStamp = 9;
     */
    timeStamp?: Timestamp; // The time when the message was sent.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.AllTargetReports
 */
export interface AllTargetReports {
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.TargetReportMessage reports = 1;
     */
    reports: TargetReportMessage[];
}
/**
 * A message for specifying the reference/target/actual time of a milestone.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.FlightMilestoneTimeMessage
 */
export interface FlightMilestoneTimeMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string flightUniqueId = 2;
     */
    flightUniqueId: string; // Inherited from FlightMessage.
    /**
     * A unique ID for the flight (preferably unique worldwide and across days).
     * Messages with the same flight unique id will be assumed to relate to the same flight.
     *
     * @generated from protobuf field: ProtobufAirTrafficSimulator.PlanningStage planningStage = 3;
     */
    planningStage: PlanningStage; // Inherited from FlightMilestoneMessage.
    /**
     * The type of milestone this message specifies.
     *
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Milestone milestone = 4;
     */
    milestone: Milestone; // Inherited from FlightMilestoneMessage.
    /**
     * Which milestone this message specifies.
     *
     * @generated from protobuf field: google.protobuf.Timestamp milestoneTime = 5;
     */
    milestoneTime?: Timestamp; // The time for the milestone.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.PreferenceInterval preferenceInterval = 6;
     */
    preferenceInterval?: PreferenceInterval; // The preference's flexibility in time for the milestone.
}
/**
 * A message for specifying the reference/target/actual position of a milestone.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.FlightMilestonePositionMessage
 */
export interface FlightMilestonePositionMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string flightUniqueId = 2;
     */
    flightUniqueId: string; // Inherited from FlightMessage.
    /**
     * A unique ID for the flight (preferably unique worldwide and across days).
     * Messages with the same flight unique id will be assumed to relate to the same flight.
     *
     * @generated from protobuf field: ProtobufAirTrafficSimulator.PlanningStage planningStage = 3;
     */
    planningStage: PlanningStage; // Inherited from FlightMilestoneMessage.
    /**
     * The type of milestone this message specifies.
     *
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Milestone milestone = 4;
     */
    milestone: Milestone; // Inherited from FlightMilestoneMessage.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.PositionAtObject position = 5;
     */
    position?: PositionAtObject; // The position identified through the name of the waypoint/stand/runway.
    /**
     * @generated from protobuf field: float preference = 6;
     */
    preference: number; // The weight of the preference. Zero value for the preference is interpreted as "information only" and can be used to send information about actual positions.
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timeStampSent = 7;
     */
    timeStampSent?: Timestamp; // The time for which when the message was sent (system time).
}
/**
 * A message describing the plan for a flight.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.PlannedFlightMessage
 */
export interface PlannedFlightMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string flightUniqueId = 2;
     */
    flightUniqueId: string; // Inherited from FlightMessage.
    /**
     * A unique ID for the flight (preferably unique worldwide and across days).
     * Messages with the same flight unique id will be assumed to relate to the same flight.
     *
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Trajectory trajectory = 3;
     */
    trajectory?: Trajectory; // The detailed trajectory.
    /**
     * @generated from protobuf field: google.protobuf.Duration minimumRunwaySeparation = 4;
     */
    minimumRunwaySeparation?: Duration; // The minimum amount of time the flight must be separated from the next flight.
    /**
     * @generated from protobuf field: google.protobuf.Duration minimumFlowSeparation = 5;
     */
    minimumFlowSeparation?: Duration; // MAAS if arrival or MDDS if departure.
}
/**
 *  A message describing a new aircraft.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewAircraftMessage
 */
export interface NewAircraftMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string aircraftId = 2;
     */
    aircraftId: string; // The unique id of the aircraft.
    /**
     * @generated from protobuf field: string aircraftType = 3;
     */
    aircraftType: string; // The unique identifier of the aircraft type.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.WakeTurbulenceCategory wakeTurbulenceCategory = 4;
     */
    wakeTurbulenceCategory: WakeTurbulenceCategory; // Optional wake turbulence category. If null derived from aircraftType.
}
/**
 * A message describing a new vehicle type.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewAircraftTypeMessage
 */
export interface NewAircraftTypeMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string vehicleTypeId = 2;
     */
    vehicleTypeId: string; // The unique id of the aircraft type.
    /**
     * @generated from protobuf field: float preferredGroundSpeed = 3;
     */
    preferredGroundSpeed: number; // The preferred speed of the vehicle type (m/s).
    /**
     * @generated from protobuf field: float maximumGroundSpeed = 4;
     */
    maximumGroundSpeed: number; // The maximum speed of the vehicle type (m/s).
    /**
     * @generated from protobuf field: float minimumTakeOffDistance = 5;
     */
    minimumTakeOffDistance: number; // The minimum runway distance that this type of vehicle needs to take off (m).
    /**
     * @generated from protobuf field: float wingSpan = 6;
     */
    wingSpan: number; // The wingspan of the vehicle type (m).
    /**
     * @generated from protobuf field: float length = 7;
     */
    length: number; // The length of the vehicle type (m).
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.WakeTurbulenceCategory wakeTurbulenceCategory = 8;
     */
    wakeTurbulenceCategory: WakeTurbulenceCategory; // The wake turbence category of a vehicle type, can be Light, Medium, Heavy or Jumbo, typically used to determine wake vortex separations.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.SpeedCategory takeOffSpeedCategory = 9;
     */
    takeOffSpeedCategory: SpeedCategory; // The takeoff speed category of a vehicle type.
    /**
     * @generated from protobuf field: google.protobuf.Duration minStartUpTime = 10;
     */
    minStartUpTime?: Duration; // The engine start up time. The aircraft cannot start moving directly when the engines are not started yet.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.AircraftClass aircraftClass = 11;
     */
    aircraftClass: AircraftClass; // The aircraft class of the vehicle type if applicable.
    /**
     * @generated from protobuf field: float height = 12;
     */
    height: number; // The height of this vehicle type (m).
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.VehicleCategory vehicleCategory = 13;
     */
    vehicleCategory: VehicleCategory; // The aircraft category.
}
/**
 * A message describing a new flight to the MadMan Library. This message must be added
 * before any other messages related to this flight.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewFlightMessage
 */
export interface NewFlightMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string flightUniqueId = 2;
     */
    flightUniqueId: string; // Inherited from FlightMessage, a unique id for the flight.
    /**
     * @generated from protobuf field: string callSign = 3;
     */
    callSign: string; // The call sign for the flight.
    /**
     * @generated from protobuf field: string departureAirport = 4;
     */
    departureAirport: string; // The ICAO code for the airport the flight departs from.
    /**
     * @generated from protobuf field: string arrivalAirport = 5;
     */
    arrivalAirport: string; // The ICAO code for the destination airport of the flight.
    /**
     * @generated from protobuf field: string aircraftId = 6;
     */
    aircraftId: string; // The unique id of the aircraft that performs the flight.
}
/**
 * Event used to inform the MadMan Library about a change in status code for a flight.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.FlightStatusCodeMessage
 */
export interface FlightStatusCodeMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string flightUniqueId = 2;
     */
    flightUniqueId: string; // Inherited from FlightMessage, a unique id for the flight.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.FlightStatusCode statusCode = 3;
     */
    statusCode: FlightStatusCode; // The status code.
}
/**
 * A message describing a new route.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewRouteMessage
 */
export interface NewRouteMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string networkId = 2;
     */
    networkId: string; // Inherited from NetworkAndObjectReferenceMessage.
    /**
     * The network's id (must be unique within the application).
     *
     * @generated from protobuf field: string routeId = 3;
     */
    routeId: string; // Corresponds to objectId Inherited from ObjectReferenceMessage.
    /**
     * The object's id (must be unique for the given object type).
     *
     * @generated from protobuf field: ProtobufAirTrafficSimulator.ObjectType routeType = 4;
     */
    routeType: ObjectType; // Inherited from ObjectReferenceMessage.
    /**
     * The object's type.
     *
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Trajectory trajectory = 5;
     */
    trajectory?: Trajectory; // A list of positions ordered chronologically.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.SpeedCategory speedCategory = 6;
     */
    speedCategory: SpeedCategory; //  The speed category applicable for this route.
}
// A message used to inform the MadMan Library to give route information to a flight,
// e.g., to assign a default a default flight route, to specifiy alternative routes,
// to change the current route to one of the alternatives or to modify a portion of a route.

/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.FlightRouteMessage
 */
export interface FlightRouteMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.z
    /**
     * @generated from protobuf field: string flightUniqueId = 2;
     */
    flightUniqueId: string; // A unique id for the flight.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.PlanningStage planningStage = 3;
     */
    planningStage: PlanningStage; // The stage of planning, see method documentation for details.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.ObjectType routeType = 4;
     */
    routeType: ObjectType; // The type of route.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Trajectory route = 5;
     */
    route?: Trajectory; // A list of positions ordered chronologically or a reference to a known route.
    /**
     * @generated from protobuf field: float preference = 6;
     */
    preference: number; // The weight of the preference. Normally only used for the planning stage reference and target.
}
/**
 * A message describing a new stand manoeuvre
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewStandManoeuvreMessage
 */
export interface NewStandManoeuvreMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string networkId = 2;
     */
    networkId: string; // Inherited from NetworkAndObjectReferenceMessage.
    /**
     * @generated from protobuf field: string routeId = 3;
     */
    routeId: string; // Corresponds to objectId Inherited from ObjectReferenceMessage.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.ObjectType routeType = 4;
     */
    routeType: ObjectType; // Inherited from ObjectReferenceMessage.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Trajectory route = 5;
     */
    route?: Trajectory; // A list of positions ordered chronologically.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.SpeedCategory speedCategory = 6;
     */
    speedCategory: SpeedCategory; // The speed category applicable for this route.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Position stand = 7;
     */
    stand?: Position; // The position of the stand.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Position endPushback = 8;
     */
    endPushback?: Position; // The position where the backward motion of the pushback manoeuvre ends.
    /**
     * @generated from protobuf field: float bearing = 9;
     */
    bearing: number; // The optional bearing of the vehicle after performing the manoeuvre.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewAirspaceMessage
 */
export interface NewAirspaceMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string airspaceId = 2;
     */
    airspaceId: string; // Inherited from NewAirspaceVolumeMessage, the id of the airspace volume that we are defining.
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.AirspaceVolumeReference includedAirspaceVolumes = 3;
     */
    includedAirspaceVolumes: AirspaceVolumeReference[]; // Inherited from NewAirspaceVolumeMessage, the included airspace volumes.
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.Position area = 4;
     */
    area: Position[]; // A polygon area defining the 2D part of the volume.
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string; // A description.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.ObjectType objectType = 6;
     */
    objectType: ObjectType; // The airspace object type.
    /**
     * @generated from protobuf field: int32 bottomFlightLevel = 7;
     */
    bottomFlightLevel: number; // The bottom flight levelø, if not given then <see cref="FlightLevel.MinValue"/> is used.
    /**
     * @generated from protobuf field: int32 topFlightLevel = 8;
     */
    topFlightLevel: number; // The top flight level, if not given <see cref="FlightLevel.MaxValue"/> is used.
}
/**
 * Creates a new sector message. A sector is volume in air or on the
 * ground for which one or more air traffic controlelrs can be responsible for.
 * The volume is described by including other already defined airspace volumes.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewSectorMessage
 */
export interface NewSectorMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string sectorId = 2;
     */
    sectorId: string; // Inherited from NewAirspaceVolumeMessage, the id of the sector that we are defining.
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string; // A description.
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.AirspaceVolumeReference includedAirspaceVolumes = 4;
     */
    includedAirspaceVolumes: AirspaceVolumeReference[]; // The included airspace volumes.
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.Position area = 5;
     */
    area: Position[]; // A polygon area defining the 2D part of the volume.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.ObjectType objectType = 6;
     */
    objectType: ObjectType; // The sector object type.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewAirBlockMessage
 */
export interface NewAirBlockMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string airBlockId = 2;
     */
    airBlockId: string; // Inherited from NewAirspaceVolumeMessage, the id of the airblock that we are defining.
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string; // A description.
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.Position area = 4;
     */
    area: Position[]; // Counter-clockwise enumeration of positions(longitude/latitude) of the volume, an optional
    /**
     * bottom level and upper level can be defined. If not defined, the airblock goes from 0 meter.
     * to double.MaxValue meters. The Altitude in the position 4D message is not taking into account.
     * Also, not the time in the Position4D class.
     *
     * @generated from protobuf field: int32 bottomFlightLevel = 5;
     */
    bottomFlightLevel: number; // The bottom flight level of this airblock.
    /**
     * @generated from protobuf field: int32 topFlightLevel = 6;
     */
    topFlightLevel: number; // The top flight level of this airblock.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.ObjectType objectType = 7;
     */
    objectType: ObjectType; // The airblock object type.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewAirspaceConfigurationMessage
 */
export interface NewAirspaceConfigurationMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string configurationId = 2;
     */
    configurationId: string; // The unique id of the configuration.
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string; // A more elaborate description of the airspace volume.
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.AirspaceVolumeReference includedAirspaceVolumes = 5;
     */
    includedAirspaceVolumes: AirspaceVolumeReference[]; // The included airspace volumes.
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.Position area = 6;
     */
    area: Position[]; // The volume id of the airspace volumes that are part of the sector. Typically, the volumes are of airlock type.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.ObjectType objectType = 7;
     */
    objectType: ObjectType; // The airspace configuration object type.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.TimeInterval
 */
export interface TimeInterval {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp starttime = 1;
     */
    starttime?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp endttime = 2;
     */
    endttime?: Timestamp;
}
/**
 * A message that tells the period when an airspace is available.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.AirspaceAvailabilityMessage
 */
export interface AirspaceAvailabilityMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string airspaceId = 2;
     */
    airspaceId: string; // The airspace id. In most cases this will refer to an airspace configuration.
    /**
     * @generated from protobuf field: google.protobuf.Timestamp startTime = 3;
     */
    startTime?: Timestamp; // The start of the time interval, inherited from TimeIntervalMessage.
    /**
     * @generated from protobuf field: google.protobuf.Timestamp endTime = 4;
     */
    endTime?: Timestamp; // The end of the time interval, inherited from TimeIntervalMessage.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.AvailabilityIntervalsMessage
 */
export interface AvailabilityIntervalsMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string objectId = 2;
     */
    objectId: string; // The airspace id. In most cases this will refer to an airspace configuration.
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.TimeInterval timeIntervals = 3;
     */
    timeIntervals: TimeInterval[];
}
/**
 * Creates a message about a new segment. If a segment with the same id exists, then the existing segment should be updated according to this message.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewSegmentMessage
 */
export interface NewSegmentMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string networkId = 2;
     */
    networkId: string; // The unique name of the network where the object is located.
    /**
     * @generated from protobuf field: string segmentId = 3;
     */
    segmentId: string; // The unique id of the segment.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.NetworkResourceType segmentType = 4;
     */
    segmentType: NetworkResourceType; // The object type.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Trajectory points = 5;
     */
    points?: Trajectory; // The trajectory given as an ordered sequence of points.
    /**
     * @generated from protobuf field: float curvature = 6;
     */
    curvature: number; // The curvature of the segment, i.e., the number of degrees the bearing changes per meter traversed.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.SegmentDirection direction = 7;
     */
    direction: SegmentDirection; // The default direction for which the segment can be traversed.
}
/**
 * A message describing a new waypoint in the network.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewWaypointMessage
 */
export interface NewWaypointMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string networkId = 2;
     */
    networkId: string; // The unique name of the network where the object is located.
    /**
     * @generated from protobuf field: string objectId = 3;
     */
    objectId: string; // The unique id for the waypoint.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.ObjectType objectType = 4;
     */
    objectType: ObjectType; // The object type for the point.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.WaypointType waypointType = 5;
     */
    waypointType: WaypointType; // The type of waypoint.
    /**
     * @generated from protobuf field: repeated string points = 6;
     */
    points: string[]; // The points within the waypoint area.
}
/**
 * A message describing a new point in the network.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewPointMessage
 */
export interface NewPointMessage {
    /**
     * @generated from protobuf field: string xmlElementName = 1;
     */
    xmlElementName: string; // Inherited from EventMessage, The XML name of the event.
    /**
     * @generated from protobuf field: string networkId = 2;
     */
    networkId: string; // The unique name of the network where the object is located.
    /**
     * @generated from protobuf field: string nodeId = 3;
     */
    nodeId: string; // The unique id for the point.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.ObjectType nodeType = 4;
     */
    nodeType: ObjectType; // The object type for the point.
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Position position = 5;
     */
    position?: Position; // The position for the point.
}
/**
 * A message sent when there is a status update w.r.t a role, this can be either a controller or a (pseudo) pilot.
 * This can be in the event that a flight has been set to tentative, or been accepted by a controller.
 *
 * @generated from protobuf message ProtobufAirTrafficSimulator.RoleConfigurationMessage
 */
export interface RoleConfigurationMessage {
    /**
     * @generated from protobuf field: string roleName = 1;
     */
    roleName: string; // Name of the role, e.g. CWP1, Pseudo-pilot1 etc.
    /**
     * @generated from protobuf field: string sectorToControl = 2;
     */
    sectorToControl: string; // Sector this role is responsible for.
    /**
     * @generated from protobuf field: string actualIdOfSectorToControl = 3;
     */
    actualIdOfSectorToControl: string; // Actual id of the sector to control.
    /**
     * @generated from protobuf field: repeated string acceptedFlights = 4;
     */
    acceptedFlights: string[]; // Flights this role has accepted responsibility for.
    /**
     * @generated from protobuf field: repeated string tentativeFlights = 5;
     */
    tentativeFlights: string[]; // Flights this role has accepted future responsibility for, will be accepted when invoked as such.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.CurrentAirspaceConfigurationMessage
 */
export interface CurrentAirspaceConfigurationMessage {
    /**
     * @generated from protobuf field: string CurrentAirspaceConfiguration = 1 [json_name = "CurrentAirspaceConfiguration"];
     */
    currentAirspaceConfiguration: string;
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.TesselatedAirspaceVolumeMessage
 */
export interface TesselatedAirspaceVolumeMessage {
    /**
     * @generated from protobuf field: string airspaceVolumeId = 1;
     */
    airspaceVolumeId: string; // Id of the tesselated airspace volume.
    /**
     * @generated from protobuf field: string belongsToAirspaceConfigId = 2;
     */
    belongsToAirspaceConfigId: string; // Id of the airspace configuration this tesselated volume belongs to.
    /**
     * @generated from protobuf field: int32 numberOfVolumesInConfig = 3;
     */
    numberOfVolumesInConfig: number; // The number of volumes in the airspace configuration this volume belongs to.
    /**
     * @generated from protobuf field: repeated ProtobufAirTrafficSimulator.TesselatedSectorSurface tesselatedSurfaces = 4;
     */
    tesselatedSurfaces: TesselatedSectorSurface[]; // A list of all the tesselated surfaces.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.NewAirspaceVolumeFlightListMessage
 */
export interface NewAirspaceVolumeFlightListMessage {
    /**
     * @generated from protobuf field: string airspaceVolumeId = 1;
     */
    airspaceVolumeId: string; // Id of the airspace volume this flight list belongs to.
    /**
     * @generated from protobuf field: repeated string flightsInVolume = 2;
     */
    flightsInVolume: string[]; // Ids of all the flights in this volume.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.AirTrafficControllerAssignmentMessage
 */
export interface AirTrafficControllerAssignmentMessage {
    /**
     * @generated from protobuf field: string airTrafficControllerId = 1;
     */
    airTrafficControllerId: string;
    /**
     * @generated from protobuf field: repeated string sectorIds = 2;
     */
    sectorIds: string[];
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.FlightEnteringAirspaceMessage
 */
export interface FlightEnteringAirspaceMessage {
    /**
     * @generated from protobuf field: string flightUniqueId = 1;
     */
    flightUniqueId: string;
    /**
     * @generated from protobuf field: string sectorId = 2;
     */
    sectorId: string;
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Position4D entryPosition = 3;
     */
    entryPosition?: Position4D;
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.Position4D exitPosition = 4;
     */
    exitPosition?: Position4D;
    /**
     * @generated from protobuf field: string entryWaypointId = 5;
     */
    entryWaypointId: string;
    /**
     * @generated from protobuf field: string exitWaypointId = 6;
     */
    exitWaypointId: string;
    /**
     * @generated from protobuf field: ProtobufAirTrafficSimulator.PlanningStage planningStage = 7;
     */
    planningStage: PlanningStage;
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.AddAcceptedFlightMessage
 */
export interface AddAcceptedFlightMessage {
    /**
     * @generated from protobuf field: string fromControllableAirspaceVolume = 1;
     */
    fromControllableAirspaceVolume: string; // Id of the controllable airspace volume that released ownership of the flight to toRole.
    /**
     * @generated from protobuf field: string toControllableAirspaceVolume = 2;
     */
    toControllableAirspaceVolume: string; // Id of the controllable airspace volume that accepted the flight, that has become the owner.
    /**
     * @generated from protobuf field: string flightId = 3;
     */
    flightId: string; // The id of the accepted flight.
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timeStamp = 4;
     */
    timeStamp?: Timestamp; // The time for when the accept happened.
}
/**
 * @generated from protobuf message ProtobufAirTrafficSimulator.AddTentativeFlightMessage
 */
export interface AddTentativeFlightMessage {
    /**
     * @generated from protobuf field: string fromControllableAirspaceVolume = 1;
     */
    fromControllableAirspaceVolume: string; // Id of the controllable airspace volume that coordinated next ownership of the flight to toRole.
    /**
     * @generated from protobuf field: string toControllableAirspaceVolume = 2;
     */
    toControllableAirspaceVolume: string; // Id of the controllable airspace volume that gains next ownership of the flight, upon acceptance.
    /**
     * @generated from protobuf field: string flightId = 3;
     */
    flightId: string; // The id of the tentative flight.
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timeStamp = 4;
     */
    timeStamp?: Timestamp; // The time for when the tentative happened.
}
// Changing the file
// protoc --js_out=import_style=commonjs,binary:. ProtobufAirTrafficSimulator.proto
// For changing file here: npm run protoc
// ###################################################################################
// * Enumerations, begin.                                                            |
//  ##################################################################################

/**
 * Enumeration of the different stages of planning.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.PlanningStage
 */
export enum PlanningStage {
    /**
     * Specifies the reference for a milestone event.
     *
     * @generated from protobuf enum value: REFERENCE = 0;
     */
    REFERENCE = 0,
    /**
     * Reference values should be sent once and therafter never modified throughout
     * the planning process.
     *
     * Specifies the target for a milestone event.
     *
     * @generated from protobuf enum value: TARGET = 1;
     */
    TARGET = 1,
    /**
     * Target values may dynamically be changed throughout the planning process
     * to reflect updated information/preferences.
     *
     * Specifies the plan for a milestone event, i.e., as planned by MadMan.
     *
     * @generated from protobuf enum value: PLAN = 2;
     */
    PLAN = 2,
    /**
     * Planned values may dynamically be changed throughout the planning process
     * to reflect updated plans from MadMan.
     *
     * Specifies that clearance has been given for a milestone event to take place.
     *
     * @generated from protobuf enum value: CLEARED = 3;
     */
    CLEARED = 3,
    /**
     * Specifies the actual of a milestone event, i.e., the real-time event.
     *
     * @generated from protobuf enum value: ACTUAL = 4;
     */
    ACTUAL = 4
}
/**
 * The different milestones we have for a flight.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.Milestone
 */
export enum Milestone {
    /**
     * Not a milestone.
     *
     * @generated from protobuf enum value: M_NONE = 0;
     */
    M_NONE = 0,
    /**
     * Specifies that we are dealing with a off block milestone.
     *
     * @generated from protobuf enum value: OFFBLOCK = 1;
     */
    OFFBLOCK = 1,
    /**
     * Off block time is when the aircraft starts moving from the stand.
     * Note that startup approval time must be earlier than the off block time.
     *
     * Specifies that we are dealing with a end-of-puchback milestone.
     *
     * @generated from protobuf enum value: END_PUSHBACK = 2;
     */
    END_PUSHBACK = 2,
    /**
     * EndPushback time is when the aircraft stops moving backward.
     *
     * Specifies that we are dealing with a runway entry milestone.
     *
     * @generated from protobuf enum value: M_RUNWAY_ENTRY = 3;
     */
    M_RUNWAY_ENTRY = 3,
    /**
     * Runway entry time is when the aircraft enters the runway entry point.
     *
     * Specifies that we are dealing with a take off milestone.
     *
     * @generated from protobuf enum value: M_TAKE_OFF = 4;
     */
    M_TAKE_OFF = 4,
    /**
     * Take off time is when the aircraft becomes airborne.
     *
     * Specifies the milestone that the departing aircraft is leaving
     *
     * @generated from protobuf enum value: LEAVING_DEPARTURE_T_M_A = 5;
     */
    LEAVING_DEPARTURE_T_M_A = 5,
    /**
     * the TMA of the departure airport.
     *
     * Specifies the milestone that the arriving aircraft is entering
     *
     * @generated from protobuf enum value: ENTERING_ARRIVAL_T_M_A = 6;
     */
    ENTERING_ARRIVAL_T_M_A = 6,
    /**
     * the TMA of the arrival airport.
     *
     * Specifies that we are dealing with an airplane passing the metering fix.
     *
     * @generated from protobuf enum value: METERING_FIX = 7;
     */
    METERING_FIX = 7,
    /**
     * The metering fix is where we are synchronising airborne and ground trajectories.
     * It is also where CTA/i4D operation ends.
     *
     * Specifies the milestone where final approach starts.
     *
     * @generated from protobuf enum value: FINAL_APPROACH = 8;
     */
    FINAL_APPROACH = 8,
    /**
     * A final approach is the last leg in an aircraft's approach to landing,
     * when the aircraft is lined up with the runway and descending for landing.
     *
     * Specifies that we are dealing with a landing milestone.
     *
     * @generated from protobuf enum value: M_LANDING = 9;
     */
    M_LANDING = 9,
    /**
     * Landing time is the time when the aircraft touch down on the runway.
     *
     * Specifies that we are dealing with a runway exit milestone.
     *
     * @generated from protobuf enum value: M_RUNWAY_EXIT = 10;
     */
    M_RUNWAY_EXIT = 10,
    /**
     * Runway exit time is when the aircraft passes the runway exit point.
     *
     * Specifies that we are dealing with a in block milestone.
     *
     * @generated from protobuf enum value: INBLOCK = 11;
     */
    INBLOCK = 11
}
/**
 * The different vehicle categories that exist.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.VehicleCategory
 */
export enum VehicleCategory {
    /**
     * @generated from protobuf enum value: VC_UNKNOWN = 0;
     */
    VC_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: AIRCRAFT = 1;
     */
    AIRCRAFT = 1,
    /**
     * @generated from protobuf enum value: GROUND_VEHICLE = 2;
     */
    GROUND_VEHICLE = 2
}
/**
 * Status code to denote the state of a flight.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.FlightStatusCode
 */
export enum FlightStatusCode {
    /**
     * The status of a flight which has not yet received a status code.
     *
     * @generated from protobuf enum value: FS_NONE = 0;
     */
    FS_NONE = 0,
    /**
     * The status of flights that are anticipated to depart and arrive according to flight plan.
     *
     * @generated from protobuf enum value: SCHEDULED = 1;
     */
    SCHEDULED = 1,
    /**
     * The status of flights that are pushed back from stand or flights that are airborne.
     *
     * @generated from protobuf enum value: ACTIVE = 2;
     */
    ACTIVE = 2,
    /**
     * The status of flights that are airborne and has changed its destination to an unscheduled airport.
     *
     * @generated from protobuf enum value: REDIRECTED = 3;
     */
    REDIRECTED = 3,
    /**
     * The status of flights that has landed at an unscheduled airport.
     *
     * @generated from protobuf enum value: DIVERTED = 4;
     */
    DIVERTED = 4,
    /**
     * The status of flights that have been cancelled.
     *
     * @generated from protobuf enum value: CANCELLED = 5;
     */
    CANCELLED = 5,
    /**
     * The status of flights that are completed.
     *
     * @generated from protobuf enum value: COMPLETED = 6;
     */
    COMPLETED = 6,
    /**
     * The status of flights that are temporarily idle, e.g., while waiting for further information.
     *
     * @generated from protobuf enum value: PENDING = 7;
     */
    PENDING = 7
}
/**
 * Enumeration of the different types of objects that can be addressed.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.ObjectType
 */
export enum ObjectType {
    /**
     * An unknown object type.
     *
     * @generated from protobuf enum value: OT_UNKNOWN = 0;
     */
    OT_UNKNOWN = 0,
    /**
     * An network defining the airport's apron (including the runway).
     *
     * @generated from protobuf enum value: AIRPORT_NETWORK = 1;
     */
    AIRPORT_NETWORK = 1,
    /**
     * An network defining the airport's TMA.
     *
     * @generated from protobuf enum value: TERMINAL_MANOEUVRING_AREA = 2;
     */
    TERMINAL_MANOEUVRING_AREA = 2,
    /**
     * A point in a network (not a waypoint).
     *
     * @generated from protobuf enum value: OT_POINT = 3;
     */
    OT_POINT = 3,
    /**
     * A segment in a network.
     *
     * @generated from protobuf enum value: OT_SEGMENT = 4;
     */
    OT_SEGMENT = 4,
    /**
     * A stand manoeuvre (e.g., pushback).
     *
     * @generated from protobuf enum value: OT_STAND_MANOEUVRE = 5;
     */
    OT_STAND_MANOEUVRE = 5,
    /**
     * A taxi route.
     *
     * @generated from protobuf enum value: TAXI_ROUTE = 6;
     */
    TAXI_ROUTE = 6,
    /**
     * A departure route from a runway to a TMA exit point or to the SID final waypoint.
     *
     * @generated from protobuf enum value: OT_DEPARTURE_ROUTE = 7;
     */
    OT_DEPARTURE_ROUTE = 7,
    /**
     * An arrival Route from a TMA entry point or from the STAR initial waypoint to a runway.
     *
     * @generated from protobuf enum value: OT_ARRIVAL_ROUTE = 8;
     */
    OT_ARRIVAL_ROUTE = 8,
    /**
     * A stand where aircrafts can be parked.
     *
     * @generated from protobuf enum value: OT_STAND = 9;
     */
    OT_STAND = 9,
    /**
     * A uniquely identifiable point.
     *
     * @generated from protobuf enum value: WAYPOINT = 10;
     */
    WAYPOINT = 10,
    /**
     * A physical runway.
     *
     * @generated from protobuf enum value: OT_RUNWAY = 11;
     */
    OT_RUNWAY = 11,
    /**
     * A runway orientation.
     *
     * @generated from protobuf enum value: RUNWAY_ORIENTATION = 12;
     */
    RUNWAY_ORIENTATION = 12,
    /**
     * An airport.
     *
     * @generated from protobuf enum value: AIRPORT = 13;
     */
    AIRPORT = 13,
    /**
     * A larger part of airspace that contains different sectors.
     *
     * @generated from protobuf enum value: AIRSPACE = 14;
     */
    AIRSPACE = 14,
    /**
     * A combination of airspace that can be together active or inactive within a period of time.
     *
     * @generated from protobuf enum value: AIRSPACE_CONFIGURATION = 15;
     */
    AIRSPACE_CONFIGURATION = 15,
    /**
     * A part of airspace or airport where one or more air traffic controllers can be responsible for.
     *
     * @generated from protobuf enum value: SECTOR = 16;
     */
    SECTOR = 16,
    /**
     * A part of airspace which represents a volume that acts as an elementary building block for other airspaces.
     *
     * @generated from protobuf enum value: AIR_BLOCK = 17;
     */
    AIR_BLOCK = 17
}
/**
 * The different types of Waypoints. Use Waypoint.IsA to evaluate the type of a waypoint.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.WaypointType
 */
export enum WaypointType {
    /**
     * Waypoint of unknown type.
     *
     * @generated from protobuf enum value: WT_NONE = 0;
     */
    WT_NONE = 0,
    /**
     * At a terminal's gate.
     *
     * @generated from protobuf enum value: GATE = 1;
     */
    GATE = 1,
    /**
     * A defined position for loading/unloading aircrafts but not a terminal's gate.
     *
     * @generated from protobuf enum value: WT_STAND = 2;
     */
    WT_STAND = 2,
    /**
     * A point where the pushback from a stand ends, i.e. where it terminates the reverse movement.
     *
     * @generated from protobuf enum value: PUSHBACK_POINT = 3;
     */
    PUSHBACK_POINT = 3,
    /**
     * A taxiway intersection.
     *
     * @generated from protobuf enum value: INTERSECTION = 4;
     */
    INTERSECTION = 4,
    /**
     * A holding position.
     *
     * @generated from protobuf enum value: WT_HOLDING_POINT = 5;
     */
    WT_HOLDING_POINT = 5,
    /**
     * A de-icing area.
     *
     * @generated from protobuf enum value: WT_DEICING_AREA = 6;
     */
    WT_DEICING_AREA = 6,
    /**
     * A taxiway intersection that marks a clearance limit.
     *
     * @generated from protobuf enum value: WT_CLEARANCE_BAR = 7;
     */
    WT_CLEARANCE_BAR = 7,
    /**
     * A point on the runway.
     *
     * @generated from protobuf enum value: RUNWAY_NODE = 8;
     */
    RUNWAY_NODE = 8,
    /**
     * A runway entry.
     *
     * @generated from protobuf enum value: WT_RUNWAY_ENTRY = 9;
     */
    WT_RUNWAY_ENTRY = 9,
    /**
     * A runway exit.
     *
     * @generated from protobuf enum value: WT_RUNWAY_EXIT = 10;
     */
    WT_RUNWAY_EXIT = 10,
    /**
     * A runway threshold.
     *
     * @generated from protobuf enum value: RUNWAY_THRESHOLD = 11;
     */
    RUNWAY_THRESHOLD = 11,
    /**
     * A waypoint in a departure or arrival route.
     *
     * @generated from protobuf enum value: WT_IN_FLIGHT = 12;
     */
    WT_IN_FLIGHT = 12
}
/**
 * The different types of network resources. Use NetworkResource.IsA to evaluate the type of a NetworkResource.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.NetworkResourceType
 */
export enum NetworkResourceType {
    /**
     * A network resource with an unknown type. This is the default type for a network resource.
     *
     * @generated from protobuf enum value: NRT_UNKNOWN = 0;
     */
    NRT_UNKNOWN = 0,
    /**
     * A network resource that represents a Point (see also class Point).
     *
     * @generated from protobuf enum value: NRT_POINT = 1;
     */
    NRT_POINT = 1,
    /**
     * A network resource that represents a Segment (see also class Segment).
     *
     * @generated from protobuf enum value: NRT_SEGMENT = 2;
     */
    NRT_SEGMENT = 2,
    /**
     * A network resource that represents a stand (see also class Stand).
     *
     * @generated from protobuf enum value: NRT_STAND = 3;
     */
    NRT_STAND = 3,
    /**
     * A network resource that represents part of (or is used by) a a StandManoeuvre.
     *
     * @generated from protobuf enum value: NRT_STAND_MANOEUVRE = 4;
     */
    NRT_STAND_MANOEUVRE = 4,
    /**
     * A network resource that represents part of the apron network at an airport.
     *
     * @generated from protobuf enum value: APRON = 5;
     */
    APRON = 5,
    /**
     * A network resource that represents part of the taxiway network at an airport.
     *
     * @generated from protobuf enum value: TAXIWAY = 6;
     */
    TAXIWAY = 6,
    /**
     * A network resource that represents a runway or part of a runway for take-off and/or landing.
     *
     * @generated from protobuf enum value: NRT_RUNWAY = 7;
     */
    NRT_RUNWAY = 7,
    /**
     * A network resource that represents a part of an ArrivalRoute.
     *
     * @generated from protobuf enum value: NTR_ARRIVAL_ROUTE = 8;
     */
    NTR_ARRIVAL_ROUTE = 8,
    /**
     * A network resource that represents a part of a DepartureRoute.
     *
     * @generated from protobuf enum value: NTR_DEPARTURE_ROUTE = 9;
     */
    NTR_DEPARTURE_ROUTE = 9,
    /**
     * A network resource that represents a holding position.
     *
     * @generated from protobuf enum value: NTR_HOLDING_POINT = 10;
     */
    NTR_HOLDING_POINT = 10,
    /**
     * A network resource that represents  taxiway intersection that marks a clearance limit.
     *
     * @generated from protobuf enum value: NTR_CLEARANCE_BAR = 11;
     */
    NTR_CLEARANCE_BAR = 11,
    /**
     * A network resource that represents a de-icing area.
     *
     * @generated from protobuf enum value: NTR_DEICING_AREA = 12;
     */
    NTR_DEICING_AREA = 12,
    /**
     * A network resource that represents the road area used for the ground vehicles.
     *
     * @generated from protobuf enum value: ROAD = 13;
     */
    ROAD = 13,
    /**
     * A network resource that represents a part of an ArrivalRoute or a DepartureRoute.
     *
     * @generated from protobuf enum value: ARRIVAL_OR_DEPARTURE_ROUTE = 14;
     */
    ARRIVAL_OR_DEPARTURE_ROUTE = 14,
    /**
     * A network resource type which represents any of the above (except unknown).
     *
     * @generated from protobuf enum value: NTR_ANY = 15;
     */
    NTR_ANY = 15
}
/**
 * Different types of activities.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.ActivityType
 */
export enum ActivityType {
    /**
     * @generated from protobuf enum value: AT_UNKNOWN = 0;
     */
    AT_UNKNOWN = 0,
    /**
     * A vehicle/aircraft movement on the ground.
     *
     * @generated from protobuf enum value: GROUND = 1;
     */
    GROUND = 1,
    /**
     * The vehicle/aircraft is towed (by a car).
     *
     * @generated from protobuf enum value: TOWING = 2;
     */
    TOWING = 2,
    /**
     * A vehicle movement due to a departure.
     *
     * @generated from protobuf enum value: TAXI_OUT = 3;
     */
    TAXI_OUT = 3,
    /**
     * A vehicle movement due to an arrival.
     *
     * @generated from protobuf enum value: TAXI_IN = 4;
     */
    TAXI_IN = 4,
    /**
     * A vehicle movement for a depature on the runway.
     *
     * @generated from protobuf enum value: AT_TAKE_OFF = 5;
     */
    AT_TAKE_OFF = 5,
    /**
     * A vehicle movement for an arrival on the runway.
     *
     * @generated from protobuf enum value: AT_LANDING = 6;
     */
    AT_LANDING = 6,
    /**
     * A vehicle movement in the air.
     *
     * @generated from protobuf enum value: AT_IN_FLIGHT = 7;
     */
    AT_IN_FLIGHT = 7,
    /**
     * A runway inspection.
     *
     * @generated from protobuf enum value: RUNWAY_INSPECTION = 8;
     */
    RUNWAY_INSPECTION = 8,
    /**
     * All activities.
     *
     * @generated from protobuf enum value: AT_ALL = 9;
     */
    AT_ALL = 9
}
/**
 * The different directions relevant for segments.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.SegmentDirection
 */
export enum SegmentDirection {
    /**
     * No direction.
     *
     * @generated from protobuf enum value: SD_NONE = 0;
     */
    SD_NONE = 0,
    /**
     * Direction following the definition of the segment.
     *
     * @generated from protobuf enum value: START_TO_END = 1;
     */
    START_TO_END = 1,
    /**
     * Direction opposite of the definition of the segment.
     *
     * @generated from protobuf enum value: END_TO_START = 2;
     */
    END_TO_START = 2,
    /**
     * Both directions.
     *
     * @generated from protobuf enum value: SD_ALL = 3;
     */
    SD_ALL = 3
}
/**
 * Enumeration of the different types of general access levels for aircrafts.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.AccessType
 */
export enum AccessType {
    /**
     * No access for any aircraft.
     *
     * @generated from protobuf enum value: CLOSED = 0;
     */
    CLOSED = 0,
    /**
     * Access for arrival flights.
     *
     * @generated from protobuf enum value: ARRIVALS = 1;
     */
    ARRIVALS = 1,
    /**
     * Access for depature flights.
     *
     * @generated from protobuf enum value: DEPARTURES = 2;
     */
    DEPARTURES = 2,
    /**
     * Access for all aircrafts.
     *
     * @generated from protobuf enum value: OPEN = 3;
     */
    OPEN = 3
}
/**
 * Enumeration of aircraft speed categories. --> This has replaced TakeOffSpeedCategory.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.SpeedCategory
 */
export enum SpeedCategory {
    /**
     * @generated from protobuf enum value: SC_UNKNOWN = 0;
     */
    SC_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: SLOW = 1;
     */
    SLOW = 1,
    /**
     * @generated from protobuf enum value: FAST = 2;
     */
    FAST = 2,
    /**
     * @generated from protobuf enum value: SC_ALL = 3;
     */
    SC_ALL = 3
}
/**
 * Enumeration of the wake turbulence categories (WTC) in increasing order.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.WakeTurbulenceCategory
 */
export enum WakeTurbulenceCategory {
    /**
     * WTC not known.
     *
     * @generated from protobuf enum value: WTC_UNKNOWN = 0;
     */
    WTC_UNKNOWN = 0,
    /**
     * Light [L] (A).
     *
     * @generated from protobuf enum value: WTC_LIGHT = 1;
     */
    WTC_LIGHT = 1,
    /**
     * Lower medium [S] (B).
     *
     * @generated from protobuf enum value: LOWER_MEDIUM = 2;
     */
    LOWER_MEDIUM = 2,
    /**
     * Upper medium [M] (C).
     *
     * @generated from protobuf enum value: UPPER_MEDIUM = 3;
     */
    UPPER_MEDIUM = 3,
    /**
     * Lower heavy [C] (D).
     *
     * @generated from protobuf enum value: LOWER_HEAVY = 4;
     */
    LOWER_HEAVY = 4,
    /**
     * Upper heavy [H] (E).
     *
     * @generated from protobuf enum value: UPPER_HEAVY = 5;
     */
    UPPER_HEAVY = 5,
    /**
     * Super heavy [J] (F).
     *
     * @generated from protobuf enum value: JUMBO = 6;
     */
    JUMBO = 6,
    /**
     * All.
     *
     * @generated from protobuf enum value: WTC_ALL = 7;
     */
    WTC_ALL = 7
}
/**
 * Represents an aviation flight category that could be either visual or instrumental meteorological conditions.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.AviationFlightCategory
 */
export enum AviationFlightCategory {
    /**
     * Visual Meteorological Conditions (visibility > 5 mi).
     *
     * @generated from protobuf enum value: V_M_C = 0;
     */
    V_M_C = 0,
    /**
     * Marginal Visual Meteorological Conditions (visibility between 3 and 5 mi).
     *
     * @generated from protobuf enum value: MARGINAL_V_M_C = 1;
     */
    MARGINAL_V_M_C = 1,
    /**
     * Instrumental Meteorological Conditions (visibility 1 mi or more but less than 3 mi).
     *
     * @generated from protobuf enum value: I_M_C = 2;
     */
    I_M_C = 2,
    /**
     * Marginal Instrumental Meteorological Conditions (visibility < 1 mi).
     *
     * @generated from protobuf enum value: MARGINAL_I_M_C = 3;
     */
    MARGINAL_I_M_C = 3
}
/**
 * Represents different levels of deicing (from level A to E taking 5, 7, 10, 12, 15, 20 minutes).
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.DeIcingLevel
 */
export enum DeIcingLevel {
    /**
     * @generated from protobuf enum value: DIL_NONE = 0;
     */
    DIL_NONE = 0,
    /**
     * @generated from protobuf enum value: DIL_LIGHT = 1;
     */
    DIL_LIGHT = 1,
    /**
     * @generated from protobuf enum value: MODERATE = 2;
     */
    MODERATE = 2,
    /**
     * @generated from protobuf enum value: SEVERE = 3;
     */
    SEVERE = 3,
    /**
     * @generated from protobuf enum value: EXTREME = 4;
     */
    EXTREME = 4
}
/**
 * Represents different levels of visibility.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.VisibilityLevel
 */
export enum VisibilityLevel {
    /**
     * High visiblity. Low visibility procedures are not required.
     *
     * @generated from protobuf enum value: HIGH = 0;
     */
    HIGH = 0,
    /**
     * Low/reduced visibility. Low visibility procedures are required.
     *
     * @generated from protobuf enum value: LOW = 1;
     */
    LOW = 1
}
/**
 * The aircraft class of an aircraft. The classes are represented by A, B, C, D, E, F where A is smallest, F is largest.
 * None if not applicable. The classes should be ordered in increasing order.
 *
 * @generated from protobuf enum ProtobufAirTrafficSimulator.AircraftClass
 */
export enum AircraftClass {
    /**
     * @generated from protobuf enum value: ACC_NONE = 0;
     */
    ACC_NONE = 0,
    /**
     * @generated from protobuf enum value: A = 1;
     */
    A = 1,
    /**
     * @generated from protobuf enum value: B = 2;
     */
    B = 2,
    /**
     * @generated from protobuf enum value: C = 3;
     */
    C = 3,
    /**
     * @generated from protobuf enum value: D = 4;
     */
    D = 4,
    /**
     * @generated from protobuf enum value: E = 5;
     */
    E = 5,
    /**
     * @generated from protobuf enum value: F = 6;
     */
    F = 6
}
// @generated message type with reflection information, may provide speed optimized methods
class PreferenceInterval$Type extends MessageType<PreferenceInterval> {
    constructor() {
        super("ProtobufAirTrafficSimulator.PreferenceInterval", [
            { no: 1, name: "maxEarlier", kind: "message", T: () => Duration },
            { no: 2, name: "maxLater", kind: "message", T: () => Duration },
            { no: 3, name: "costPerSecondEarlier", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "costPerSecondLater", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<PreferenceInterval>): PreferenceInterval {
        const message = { costPerSecondEarlier: 0, costPerSecondLater: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PreferenceInterval>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PreferenceInterval): PreferenceInterval {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Duration maxEarlier */ 1:
                    message.maxEarlier = Duration.internalBinaryRead(reader, reader.uint32(), options, message.maxEarlier);
                    break;
                case /* google.protobuf.Duration maxLater */ 2:
                    message.maxLater = Duration.internalBinaryRead(reader, reader.uint32(), options, message.maxLater);
                    break;
                case /* float costPerSecondEarlier */ 3:
                    message.costPerSecondEarlier = reader.float();
                    break;
                case /* float costPerSecondLater */ 4:
                    message.costPerSecondLater = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PreferenceInterval, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Duration maxEarlier = 1; */
        if (message.maxEarlier)
            Duration.internalBinaryWrite(message.maxEarlier, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration maxLater = 2; */
        if (message.maxLater)
            Duration.internalBinaryWrite(message.maxLater, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* float costPerSecondEarlier = 3; */
        if (message.costPerSecondEarlier !== 0)
            writer.tag(3, WireType.Bit32).float(message.costPerSecondEarlier);
        /* float costPerSecondLater = 4; */
        if (message.costPerSecondLater !== 0)
            writer.tag(4, WireType.Bit32).float(message.costPerSecondLater);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.PreferenceInterval
 */
export const PreferenceInterval = new PreferenceInterval$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Position4D$Type extends MessageType<Position4D> {
    constructor() {
        super("ProtobufAirTrafficSimulator.Position4D", [
            { no: 1, name: "time", kind: "message", T: () => Timestamp },
            { no: 2, name: "latitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "longitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "altitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<Position4D>): Position4D {
        const message = { latitude: 0, longitude: 0, altitude: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Position4D>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Position4D): Position4D {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp time */ 1:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* float latitude */ 2:
                    message.latitude = reader.float();
                    break;
                case /* float longitude */ 3:
                    message.longitude = reader.float();
                    break;
                case /* float altitude */ 4:
                    message.altitude = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Position4D, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp time = 1; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* float latitude = 2; */
        if (message.latitude !== 0)
            writer.tag(2, WireType.Bit32).float(message.latitude);
        /* float longitude = 3; */
        if (message.longitude !== 0)
            writer.tag(3, WireType.Bit32).float(message.longitude);
        /* float altitude = 4; */
        if (message.altitude !== 0)
            writer.tag(4, WireType.Bit32).float(message.altitude);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.Position4D
 */
export const Position4D = new Position4D$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AvailabilitySchedule$Type extends MessageType<AvailabilitySchedule> {
    constructor() {
        super("ProtobufAirTrafficSimulator.AvailabilitySchedule", [
            { no: 1, name: "availabilityIntervals", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AvailabilityIntervalsMessage }
        ]);
    }
    create(value?: PartialMessage<AvailabilitySchedule>): AvailabilitySchedule {
        const message = { availabilityIntervals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AvailabilitySchedule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AvailabilitySchedule): AvailabilitySchedule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProtobufAirTrafficSimulator.AvailabilityIntervalsMessage availabilityIntervals */ 1:
                    message.availabilityIntervals.push(AvailabilityIntervalsMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AvailabilitySchedule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ProtobufAirTrafficSimulator.AvailabilityIntervalsMessage availabilityIntervals = 1; */
        for (let i = 0; i < message.availabilityIntervals.length; i++)
            AvailabilityIntervalsMessage.internalBinaryWrite(message.availabilityIntervals[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.AvailabilitySchedule
 */
export const AvailabilitySchedule = new AvailabilitySchedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Position4DCartesian$Type extends MessageType<Position4DCartesian> {
    constructor() {
        super("ProtobufAirTrafficSimulator.Position4DCartesian", [
            { no: 1, name: "time", kind: "message", T: () => Timestamp },
            { no: 2, name: "x", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "y", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "z", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "milestone", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.Milestone", Milestone] }
        ]);
    }
    create(value?: PartialMessage<Position4DCartesian>): Position4DCartesian {
        const message = { x: 0, y: 0, z: 0, milestone: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Position4DCartesian>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Position4DCartesian): Position4DCartesian {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp time */ 1:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* float x */ 2:
                    message.x = reader.float();
                    break;
                case /* float y */ 3:
                    message.y = reader.float();
                    break;
                case /* float z */ 4:
                    message.z = reader.float();
                    break;
                case /* ProtobufAirTrafficSimulator.Milestone milestone */ 5:
                    message.milestone = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Position4DCartesian, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp time = 1; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* float x = 2; */
        if (message.x !== 0)
            writer.tag(2, WireType.Bit32).float(message.x);
        /* float y = 3; */
        if (message.y !== 0)
            writer.tag(3, WireType.Bit32).float(message.y);
        /* float z = 4; */
        if (message.z !== 0)
            writer.tag(4, WireType.Bit32).float(message.z);
        /* ProtobufAirTrafficSimulator.Milestone milestone = 5; */
        if (message.milestone !== 0)
            writer.tag(5, WireType.Varint).int32(message.milestone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.Position4DCartesian
 */
export const Position4DCartesian = new Position4DCartesian$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PositionAtObject$Type extends MessageType<PositionAtObject> {
    constructor() {
        super("ProtobufAirTrafficSimulator.PositionAtObject", [
            { no: 1, name: "time", kind: "message", T: () => Timestamp },
            { no: 2, name: "objectId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "objectType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.ObjectType", ObjectType] },
            { no: 4, name: "milestone", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.Milestone", Milestone] }
        ]);
    }
    create(value?: PartialMessage<PositionAtObject>): PositionAtObject {
        const message = { objectId: "", objectType: 0, milestone: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PositionAtObject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PositionAtObject): PositionAtObject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp time */ 1:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* string objectId */ 2:
                    message.objectId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.ObjectType objectType */ 3:
                    message.objectType = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.Milestone milestone */ 4:
                    message.milestone = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PositionAtObject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp time = 1; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string objectId = 2; */
        if (message.objectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.objectId);
        /* ProtobufAirTrafficSimulator.ObjectType objectType = 3; */
        if (message.objectType !== 0)
            writer.tag(3, WireType.Varint).int32(message.objectType);
        /* ProtobufAirTrafficSimulator.Milestone milestone = 4; */
        if (message.milestone !== 0)
            writer.tag(4, WireType.Varint).int32(message.milestone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.PositionAtObject
 */
export const PositionAtObject = new PositionAtObject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Position$Type extends MessageType<Position> {
    constructor() {
        super("ProtobufAirTrafficSimulator.Position", [
            { no: 1, name: "position4D", kind: "message", oneof: "position", T: () => Position4D },
            { no: 2, name: "position4DCartesian", kind: "message", oneof: "position", T: () => Position4DCartesian },
            { no: 3, name: "positionAtObject", kind: "message", oneof: "position", T: () => PositionAtObject }
        ]);
    }
    create(value?: PartialMessage<Position>): Position {
        const message = { position: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Position>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Position): Position {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ProtobufAirTrafficSimulator.Position4D position4D */ 1:
                    message.position = {
                        oneofKind: "position4D",
                        position4D: Position4D.internalBinaryRead(reader, reader.uint32(), options, (message.position as any).position4D)
                    };
                    break;
                case /* ProtobufAirTrafficSimulator.Position4DCartesian position4DCartesian */ 2:
                    message.position = {
                        oneofKind: "position4DCartesian",
                        position4DCartesian: Position4DCartesian.internalBinaryRead(reader, reader.uint32(), options, (message.position as any).position4DCartesian)
                    };
                    break;
                case /* ProtobufAirTrafficSimulator.PositionAtObject positionAtObject */ 3:
                    message.position = {
                        oneofKind: "positionAtObject",
                        positionAtObject: PositionAtObject.internalBinaryRead(reader, reader.uint32(), options, (message.position as any).positionAtObject)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Position, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ProtobufAirTrafficSimulator.Position4D position4D = 1; */
        if (message.position.oneofKind === "position4D")
            Position4D.internalBinaryWrite(message.position.position4D, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ProtobufAirTrafficSimulator.Position4DCartesian position4DCartesian = 2; */
        if (message.position.oneofKind === "position4DCartesian")
            Position4DCartesian.internalBinaryWrite(message.position.position4DCartesian, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* ProtobufAirTrafficSimulator.PositionAtObject positionAtObject = 3; */
        if (message.position.oneofKind === "positionAtObject")
            PositionAtObject.internalBinaryWrite(message.position.positionAtObject, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.Position
 */
export const Position = new Position$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AirspaceVolumeReference$Type extends MessageType<AirspaceVolumeReference> {
    constructor() {
        super("ProtobufAirTrafficSimulator.AirspaceVolumeReference", [
            { no: 1, name: "volumeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "bottomFlightLevel", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "topFlightLevel", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<AirspaceVolumeReference>): AirspaceVolumeReference {
        const message = { volumeId: "", bottomFlightLevel: 0, topFlightLevel: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AirspaceVolumeReference>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AirspaceVolumeReference): AirspaceVolumeReference {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string volumeId */ 1:
                    message.volumeId = reader.string();
                    break;
                case /* int32 bottomFlightLevel */ 2:
                    message.bottomFlightLevel = reader.int32();
                    break;
                case /* int32 topFlightLevel */ 3:
                    message.topFlightLevel = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AirspaceVolumeReference, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string volumeId = 1; */
        if (message.volumeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.volumeId);
        /* int32 bottomFlightLevel = 2; */
        if (message.bottomFlightLevel !== 0)
            writer.tag(2, WireType.Varint).int32(message.bottomFlightLevel);
        /* int32 topFlightLevel = 3; */
        if (message.topFlightLevel !== 0)
            writer.tag(3, WireType.Varint).int32(message.topFlightLevel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.AirspaceVolumeReference
 */
export const AirspaceVolumeReference = new AirspaceVolumeReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TesselatedSectorSurface$Type extends MessageType<TesselatedSectorSurface> {
    constructor() {
        super("ProtobufAirTrafficSimulator.TesselatedSectorSurface", [
            { no: 1, name: "corners", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Position },
            { no: 2, name: "triangles", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<TesselatedSectorSurface>): TesselatedSectorSurface {
        const message = { corners: [], triangles: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TesselatedSectorSurface>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TesselatedSectorSurface): TesselatedSectorSurface {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProtobufAirTrafficSimulator.Position corners */ 1:
                    message.corners.push(Position.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated int32 triangles */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.triangles.push(reader.int32());
                    else
                        message.triangles.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TesselatedSectorSurface, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ProtobufAirTrafficSimulator.Position corners = 1; */
        for (let i = 0; i < message.corners.length; i++)
            Position.internalBinaryWrite(message.corners[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated int32 triangles = 2; */
        if (message.triangles.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.triangles.length; i++)
                writer.int32(message.triangles[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.TesselatedSectorSurface
 */
export const TesselatedSectorSurface = new TesselatedSectorSurface$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Trajectory$Type extends MessageType<Trajectory> {
    constructor() {
        super("ProtobufAirTrafficSimulator.Trajectory", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "trajectory", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Position }
        ]);
    }
    create(value?: PartialMessage<Trajectory>): Trajectory {
        const message = { id: "", trajectory: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Trajectory>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Trajectory): Trajectory {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated ProtobufAirTrafficSimulator.Position trajectory */ 2:
                    message.trajectory.push(Position.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Trajectory, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated ProtobufAirTrafficSimulator.Position trajectory = 2; */
        for (let i = 0; i < message.trajectory.length; i++)
            Position.internalBinaryWrite(message.trajectory[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.Trajectory
 */
export const Trajectory = new Trajectory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitialisationCompleted$Type extends MessageType<InitialisationCompleted> {
    constructor() {
        super("ProtobufAirTrafficSimulator.InitialisationCompleted", [
            { no: 1, name: "completionTime", kind: "message", T: () => Timestamp },
            { no: 2, name: "numAirspaceConfigs", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "numAirspaces", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "numSectors", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "numAirblocks", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "numWaypoints", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<InitialisationCompleted>): InitialisationCompleted {
        const message = { numAirspaceConfigs: 0, numAirspaces: 0, numSectors: 0, numAirblocks: 0, numWaypoints: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InitialisationCompleted>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitialisationCompleted): InitialisationCompleted {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp completionTime */ 1:
                    message.completionTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.completionTime);
                    break;
                case /* int32 numAirspaceConfigs */ 2:
                    message.numAirspaceConfigs = reader.int32();
                    break;
                case /* int32 numAirspaces */ 3:
                    message.numAirspaces = reader.int32();
                    break;
                case /* int32 numSectors */ 4:
                    message.numSectors = reader.int32();
                    break;
                case /* int32 numAirblocks */ 5:
                    message.numAirblocks = reader.int32();
                    break;
                case /* int32 numWaypoints */ 6:
                    message.numWaypoints = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitialisationCompleted, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp completionTime = 1; */
        if (message.completionTime)
            Timestamp.internalBinaryWrite(message.completionTime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 numAirspaceConfigs = 2; */
        if (message.numAirspaceConfigs !== 0)
            writer.tag(2, WireType.Varint).int32(message.numAirspaceConfigs);
        /* int32 numAirspaces = 3; */
        if (message.numAirspaces !== 0)
            writer.tag(3, WireType.Varint).int32(message.numAirspaces);
        /* int32 numSectors = 4; */
        if (message.numSectors !== 0)
            writer.tag(4, WireType.Varint).int32(message.numSectors);
        /* int32 numAirblocks = 5; */
        if (message.numAirblocks !== 0)
            writer.tag(5, WireType.Varint).int32(message.numAirblocks);
        /* int32 numWaypoints = 6; */
        if (message.numWaypoints !== 0)
            writer.tag(6, WireType.Varint).int32(message.numWaypoints);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.InitialisationCompleted
 */
export const InitialisationCompleted = new InitialisationCompleted$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SimulatorTime$Type extends MessageType<SimulatorTime> {
    constructor() {
        super("ProtobufAirTrafficSimulator.SimulatorTime", [
            { no: 1, name: "time", kind: "message", T: () => Timestamp },
            { no: 2, name: "speedFactor", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<SimulatorTime>): SimulatorTime {
        const message = { speedFactor: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SimulatorTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SimulatorTime): SimulatorTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp time */ 1:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* float speedFactor */ 2:
                    message.speedFactor = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SimulatorTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp time = 1; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* float speedFactor = 2; */
        if (message.speedFactor !== 0)
            writer.tag(2, WireType.Bit32).float(message.speedFactor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.SimulatorTime
 */
export const SimulatorTime = new SimulatorTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TargetReportMessage$Type extends MessageType<TargetReportMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.TargetReportMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "vehicleId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "time", kind: "message", T: () => Timestamp },
            { no: 4, name: "latitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "longitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "altitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "speed", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "bearing", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 9, name: "timeStamp", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<TargetReportMessage>): TargetReportMessage {
        const message = { xmlElementName: "", vehicleId: "", latitude: 0, longitude: 0, altitude: 0, speed: 0, bearing: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TargetReportMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TargetReportMessage): TargetReportMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string vehicleId */ 2:
                    message.vehicleId = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 3:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* float latitude */ 4:
                    message.latitude = reader.float();
                    break;
                case /* float longitude */ 5:
                    message.longitude = reader.float();
                    break;
                case /* float altitude */ 6:
                    message.altitude = reader.float();
                    break;
                case /* float speed */ 7:
                    message.speed = reader.float();
                    break;
                case /* float bearing */ 8:
                    message.bearing = reader.float();
                    break;
                case /* google.protobuf.Timestamp timeStamp */ 9:
                    message.timeStamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timeStamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TargetReportMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string vehicleId = 2; */
        if (message.vehicleId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.vehicleId);
        /* google.protobuf.Timestamp time = 3; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* float latitude = 4; */
        if (message.latitude !== 0)
            writer.tag(4, WireType.Bit32).float(message.latitude);
        /* float longitude = 5; */
        if (message.longitude !== 0)
            writer.tag(5, WireType.Bit32).float(message.longitude);
        /* float altitude = 6; */
        if (message.altitude !== 0)
            writer.tag(6, WireType.Bit32).float(message.altitude);
        /* float speed = 7; */
        if (message.speed !== 0)
            writer.tag(7, WireType.Bit32).float(message.speed);
        /* float bearing = 8; */
        if (message.bearing !== 0)
            writer.tag(8, WireType.Bit32).float(message.bearing);
        /* google.protobuf.Timestamp timeStamp = 9; */
        if (message.timeStamp)
            Timestamp.internalBinaryWrite(message.timeStamp, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.TargetReportMessage
 */
export const TargetReportMessage = new TargetReportMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllTargetReports$Type extends MessageType<AllTargetReports> {
    constructor() {
        super("ProtobufAirTrafficSimulator.AllTargetReports", [
            { no: 1, name: "reports", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TargetReportMessage }
        ]);
    }
    create(value?: PartialMessage<AllTargetReports>): AllTargetReports {
        const message = { reports: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllTargetReports>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllTargetReports): AllTargetReports {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ProtobufAirTrafficSimulator.TargetReportMessage reports */ 1:
                    message.reports.push(TargetReportMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllTargetReports, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ProtobufAirTrafficSimulator.TargetReportMessage reports = 1; */
        for (let i = 0; i < message.reports.length; i++)
            TargetReportMessage.internalBinaryWrite(message.reports[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.AllTargetReports
 */
export const AllTargetReports = new AllTargetReports$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlightMilestoneTimeMessage$Type extends MessageType<FlightMilestoneTimeMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.FlightMilestoneTimeMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flightUniqueId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "planningStage", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.PlanningStage", PlanningStage] },
            { no: 4, name: "milestone", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.Milestone", Milestone] },
            { no: 5, name: "milestoneTime", kind: "message", T: () => Timestamp },
            { no: 6, name: "preferenceInterval", kind: "message", T: () => PreferenceInterval }
        ]);
    }
    create(value?: PartialMessage<FlightMilestoneTimeMessage>): FlightMilestoneTimeMessage {
        const message = { xmlElementName: "", flightUniqueId: "", planningStage: 0, milestone: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlightMilestoneTimeMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlightMilestoneTimeMessage): FlightMilestoneTimeMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string flightUniqueId */ 2:
                    message.flightUniqueId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.PlanningStage planningStage */ 3:
                    message.planningStage = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.Milestone milestone */ 4:
                    message.milestone = reader.int32();
                    break;
                case /* google.protobuf.Timestamp milestoneTime */ 5:
                    message.milestoneTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.milestoneTime);
                    break;
                case /* ProtobufAirTrafficSimulator.PreferenceInterval preferenceInterval */ 6:
                    message.preferenceInterval = PreferenceInterval.internalBinaryRead(reader, reader.uint32(), options, message.preferenceInterval);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlightMilestoneTimeMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string flightUniqueId = 2; */
        if (message.flightUniqueId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flightUniqueId);
        /* ProtobufAirTrafficSimulator.PlanningStage planningStage = 3; */
        if (message.planningStage !== 0)
            writer.tag(3, WireType.Varint).int32(message.planningStage);
        /* ProtobufAirTrafficSimulator.Milestone milestone = 4; */
        if (message.milestone !== 0)
            writer.tag(4, WireType.Varint).int32(message.milestone);
        /* google.protobuf.Timestamp milestoneTime = 5; */
        if (message.milestoneTime)
            Timestamp.internalBinaryWrite(message.milestoneTime, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* ProtobufAirTrafficSimulator.PreferenceInterval preferenceInterval = 6; */
        if (message.preferenceInterval)
            PreferenceInterval.internalBinaryWrite(message.preferenceInterval, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.FlightMilestoneTimeMessage
 */
export const FlightMilestoneTimeMessage = new FlightMilestoneTimeMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlightMilestonePositionMessage$Type extends MessageType<FlightMilestonePositionMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.FlightMilestonePositionMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flightUniqueId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "planningStage", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.PlanningStage", PlanningStage] },
            { no: 4, name: "milestone", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.Milestone", Milestone] },
            { no: 5, name: "position", kind: "message", T: () => PositionAtObject },
            { no: 6, name: "preference", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "timeStampSent", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<FlightMilestonePositionMessage>): FlightMilestonePositionMessage {
        const message = { xmlElementName: "", flightUniqueId: "", planningStage: 0, milestone: 0, preference: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlightMilestonePositionMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlightMilestonePositionMessage): FlightMilestonePositionMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string flightUniqueId */ 2:
                    message.flightUniqueId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.PlanningStage planningStage */ 3:
                    message.planningStage = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.Milestone milestone */ 4:
                    message.milestone = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.PositionAtObject position */ 5:
                    message.position = PositionAtObject.internalBinaryRead(reader, reader.uint32(), options, message.position);
                    break;
                case /* float preference */ 6:
                    message.preference = reader.float();
                    break;
                case /* google.protobuf.Timestamp timeStampSent */ 7:
                    message.timeStampSent = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timeStampSent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlightMilestonePositionMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string flightUniqueId = 2; */
        if (message.flightUniqueId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flightUniqueId);
        /* ProtobufAirTrafficSimulator.PlanningStage planningStage = 3; */
        if (message.planningStage !== 0)
            writer.tag(3, WireType.Varint).int32(message.planningStage);
        /* ProtobufAirTrafficSimulator.Milestone milestone = 4; */
        if (message.milestone !== 0)
            writer.tag(4, WireType.Varint).int32(message.milestone);
        /* ProtobufAirTrafficSimulator.PositionAtObject position = 5; */
        if (message.position)
            PositionAtObject.internalBinaryWrite(message.position, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* float preference = 6; */
        if (message.preference !== 0)
            writer.tag(6, WireType.Bit32).float(message.preference);
        /* google.protobuf.Timestamp timeStampSent = 7; */
        if (message.timeStampSent)
            Timestamp.internalBinaryWrite(message.timeStampSent, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.FlightMilestonePositionMessage
 */
export const FlightMilestonePositionMessage = new FlightMilestonePositionMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlannedFlightMessage$Type extends MessageType<PlannedFlightMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.PlannedFlightMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flightUniqueId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "trajectory", kind: "message", T: () => Trajectory },
            { no: 4, name: "minimumRunwaySeparation", kind: "message", T: () => Duration },
            { no: 5, name: "minimumFlowSeparation", kind: "message", T: () => Duration }
        ]);
    }
    create(value?: PartialMessage<PlannedFlightMessage>): PlannedFlightMessage {
        const message = { xmlElementName: "", flightUniqueId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlannedFlightMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlannedFlightMessage): PlannedFlightMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string flightUniqueId */ 2:
                    message.flightUniqueId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.Trajectory trajectory */ 3:
                    message.trajectory = Trajectory.internalBinaryRead(reader, reader.uint32(), options, message.trajectory);
                    break;
                case /* google.protobuf.Duration minimumRunwaySeparation */ 4:
                    message.minimumRunwaySeparation = Duration.internalBinaryRead(reader, reader.uint32(), options, message.minimumRunwaySeparation);
                    break;
                case /* google.protobuf.Duration minimumFlowSeparation */ 5:
                    message.minimumFlowSeparation = Duration.internalBinaryRead(reader, reader.uint32(), options, message.minimumFlowSeparation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlannedFlightMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string flightUniqueId = 2; */
        if (message.flightUniqueId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flightUniqueId);
        /* ProtobufAirTrafficSimulator.Trajectory trajectory = 3; */
        if (message.trajectory)
            Trajectory.internalBinaryWrite(message.trajectory, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration minimumRunwaySeparation = 4; */
        if (message.minimumRunwaySeparation)
            Duration.internalBinaryWrite(message.minimumRunwaySeparation, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration minimumFlowSeparation = 5; */
        if (message.minimumFlowSeparation)
            Duration.internalBinaryWrite(message.minimumFlowSeparation, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.PlannedFlightMessage
 */
export const PlannedFlightMessage = new PlannedFlightMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewAircraftMessage$Type extends MessageType<NewAircraftMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewAircraftMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "aircraftId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "aircraftType", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "wakeTurbulenceCategory", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.WakeTurbulenceCategory", WakeTurbulenceCategory] }
        ]);
    }
    create(value?: PartialMessage<NewAircraftMessage>): NewAircraftMessage {
        const message = { xmlElementName: "", aircraftId: "", aircraftType: "", wakeTurbulenceCategory: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewAircraftMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewAircraftMessage): NewAircraftMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string aircraftId */ 2:
                    message.aircraftId = reader.string();
                    break;
                case /* string aircraftType */ 3:
                    message.aircraftType = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.WakeTurbulenceCategory wakeTurbulenceCategory */ 4:
                    message.wakeTurbulenceCategory = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewAircraftMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string aircraftId = 2; */
        if (message.aircraftId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.aircraftId);
        /* string aircraftType = 3; */
        if (message.aircraftType !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.aircraftType);
        /* ProtobufAirTrafficSimulator.WakeTurbulenceCategory wakeTurbulenceCategory = 4; */
        if (message.wakeTurbulenceCategory !== 0)
            writer.tag(4, WireType.Varint).int32(message.wakeTurbulenceCategory);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewAircraftMessage
 */
export const NewAircraftMessage = new NewAircraftMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewAircraftTypeMessage$Type extends MessageType<NewAircraftTypeMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewAircraftTypeMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "vehicleTypeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "preferredGroundSpeed", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "maximumGroundSpeed", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "minimumTakeOffDistance", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "wingSpan", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "length", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "wakeTurbulenceCategory", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.WakeTurbulenceCategory", WakeTurbulenceCategory] },
            { no: 9, name: "takeOffSpeedCategory", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.SpeedCategory", SpeedCategory] },
            { no: 10, name: "minStartUpTime", kind: "message", T: () => Duration },
            { no: 11, name: "aircraftClass", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.AircraftClass", AircraftClass] },
            { no: 12, name: "height", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 13, name: "vehicleCategory", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.VehicleCategory", VehicleCategory] }
        ]);
    }
    create(value?: PartialMessage<NewAircraftTypeMessage>): NewAircraftTypeMessage {
        const message = { xmlElementName: "", vehicleTypeId: "", preferredGroundSpeed: 0, maximumGroundSpeed: 0, minimumTakeOffDistance: 0, wingSpan: 0, length: 0, wakeTurbulenceCategory: 0, takeOffSpeedCategory: 0, aircraftClass: 0, height: 0, vehicleCategory: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewAircraftTypeMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewAircraftTypeMessage): NewAircraftTypeMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string vehicleTypeId */ 2:
                    message.vehicleTypeId = reader.string();
                    break;
                case /* float preferredGroundSpeed */ 3:
                    message.preferredGroundSpeed = reader.float();
                    break;
                case /* float maximumGroundSpeed */ 4:
                    message.maximumGroundSpeed = reader.float();
                    break;
                case /* float minimumTakeOffDistance */ 5:
                    message.minimumTakeOffDistance = reader.float();
                    break;
                case /* float wingSpan */ 6:
                    message.wingSpan = reader.float();
                    break;
                case /* float length */ 7:
                    message.length = reader.float();
                    break;
                case /* ProtobufAirTrafficSimulator.WakeTurbulenceCategory wakeTurbulenceCategory */ 8:
                    message.wakeTurbulenceCategory = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.SpeedCategory takeOffSpeedCategory */ 9:
                    message.takeOffSpeedCategory = reader.int32();
                    break;
                case /* google.protobuf.Duration minStartUpTime */ 10:
                    message.minStartUpTime = Duration.internalBinaryRead(reader, reader.uint32(), options, message.minStartUpTime);
                    break;
                case /* ProtobufAirTrafficSimulator.AircraftClass aircraftClass */ 11:
                    message.aircraftClass = reader.int32();
                    break;
                case /* float height */ 12:
                    message.height = reader.float();
                    break;
                case /* ProtobufAirTrafficSimulator.VehicleCategory vehicleCategory */ 13:
                    message.vehicleCategory = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewAircraftTypeMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string vehicleTypeId = 2; */
        if (message.vehicleTypeId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.vehicleTypeId);
        /* float preferredGroundSpeed = 3; */
        if (message.preferredGroundSpeed !== 0)
            writer.tag(3, WireType.Bit32).float(message.preferredGroundSpeed);
        /* float maximumGroundSpeed = 4; */
        if (message.maximumGroundSpeed !== 0)
            writer.tag(4, WireType.Bit32).float(message.maximumGroundSpeed);
        /* float minimumTakeOffDistance = 5; */
        if (message.minimumTakeOffDistance !== 0)
            writer.tag(5, WireType.Bit32).float(message.minimumTakeOffDistance);
        /* float wingSpan = 6; */
        if (message.wingSpan !== 0)
            writer.tag(6, WireType.Bit32).float(message.wingSpan);
        /* float length = 7; */
        if (message.length !== 0)
            writer.tag(7, WireType.Bit32).float(message.length);
        /* ProtobufAirTrafficSimulator.WakeTurbulenceCategory wakeTurbulenceCategory = 8; */
        if (message.wakeTurbulenceCategory !== 0)
            writer.tag(8, WireType.Varint).int32(message.wakeTurbulenceCategory);
        /* ProtobufAirTrafficSimulator.SpeedCategory takeOffSpeedCategory = 9; */
        if (message.takeOffSpeedCategory !== 0)
            writer.tag(9, WireType.Varint).int32(message.takeOffSpeedCategory);
        /* google.protobuf.Duration minStartUpTime = 10; */
        if (message.minStartUpTime)
            Duration.internalBinaryWrite(message.minStartUpTime, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* ProtobufAirTrafficSimulator.AircraftClass aircraftClass = 11; */
        if (message.aircraftClass !== 0)
            writer.tag(11, WireType.Varint).int32(message.aircraftClass);
        /* float height = 12; */
        if (message.height !== 0)
            writer.tag(12, WireType.Bit32).float(message.height);
        /* ProtobufAirTrafficSimulator.VehicleCategory vehicleCategory = 13; */
        if (message.vehicleCategory !== 0)
            writer.tag(13, WireType.Varint).int32(message.vehicleCategory);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewAircraftTypeMessage
 */
export const NewAircraftTypeMessage = new NewAircraftTypeMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewFlightMessage$Type extends MessageType<NewFlightMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewFlightMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flightUniqueId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "callSign", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "departureAirport", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "arrivalAirport", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "aircraftId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NewFlightMessage>): NewFlightMessage {
        const message = { xmlElementName: "", flightUniqueId: "", callSign: "", departureAirport: "", arrivalAirport: "", aircraftId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewFlightMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewFlightMessage): NewFlightMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string flightUniqueId */ 2:
                    message.flightUniqueId = reader.string();
                    break;
                case /* string callSign */ 3:
                    message.callSign = reader.string();
                    break;
                case /* string departureAirport */ 4:
                    message.departureAirport = reader.string();
                    break;
                case /* string arrivalAirport */ 5:
                    message.arrivalAirport = reader.string();
                    break;
                case /* string aircraftId */ 6:
                    message.aircraftId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewFlightMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string flightUniqueId = 2; */
        if (message.flightUniqueId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flightUniqueId);
        /* string callSign = 3; */
        if (message.callSign !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.callSign);
        /* string departureAirport = 4; */
        if (message.departureAirport !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.departureAirport);
        /* string arrivalAirport = 5; */
        if (message.arrivalAirport !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.arrivalAirport);
        /* string aircraftId = 6; */
        if (message.aircraftId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.aircraftId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewFlightMessage
 */
export const NewFlightMessage = new NewFlightMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlightStatusCodeMessage$Type extends MessageType<FlightStatusCodeMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.FlightStatusCodeMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flightUniqueId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "statusCode", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.FlightStatusCode", FlightStatusCode] }
        ]);
    }
    create(value?: PartialMessage<FlightStatusCodeMessage>): FlightStatusCodeMessage {
        const message = { xmlElementName: "", flightUniqueId: "", statusCode: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlightStatusCodeMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlightStatusCodeMessage): FlightStatusCodeMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string flightUniqueId */ 2:
                    message.flightUniqueId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.FlightStatusCode statusCode */ 3:
                    message.statusCode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlightStatusCodeMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string flightUniqueId = 2; */
        if (message.flightUniqueId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flightUniqueId);
        /* ProtobufAirTrafficSimulator.FlightStatusCode statusCode = 3; */
        if (message.statusCode !== 0)
            writer.tag(3, WireType.Varint).int32(message.statusCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.FlightStatusCodeMessage
 */
export const FlightStatusCodeMessage = new FlightStatusCodeMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewRouteMessage$Type extends MessageType<NewRouteMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewRouteMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "networkId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "routeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "routeType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.ObjectType", ObjectType] },
            { no: 5, name: "trajectory", kind: "message", T: () => Trajectory },
            { no: 6, name: "speedCategory", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.SpeedCategory", SpeedCategory] }
        ]);
    }
    create(value?: PartialMessage<NewRouteMessage>): NewRouteMessage {
        const message = { xmlElementName: "", networkId: "", routeId: "", routeType: 0, speedCategory: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewRouteMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewRouteMessage): NewRouteMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string networkId */ 2:
                    message.networkId = reader.string();
                    break;
                case /* string routeId */ 3:
                    message.routeId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.ObjectType routeType */ 4:
                    message.routeType = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.Trajectory trajectory */ 5:
                    message.trajectory = Trajectory.internalBinaryRead(reader, reader.uint32(), options, message.trajectory);
                    break;
                case /* ProtobufAirTrafficSimulator.SpeedCategory speedCategory */ 6:
                    message.speedCategory = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewRouteMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string networkId = 2; */
        if (message.networkId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.networkId);
        /* string routeId = 3; */
        if (message.routeId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.routeId);
        /* ProtobufAirTrafficSimulator.ObjectType routeType = 4; */
        if (message.routeType !== 0)
            writer.tag(4, WireType.Varint).int32(message.routeType);
        /* ProtobufAirTrafficSimulator.Trajectory trajectory = 5; */
        if (message.trajectory)
            Trajectory.internalBinaryWrite(message.trajectory, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* ProtobufAirTrafficSimulator.SpeedCategory speedCategory = 6; */
        if (message.speedCategory !== 0)
            writer.tag(6, WireType.Varint).int32(message.speedCategory);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewRouteMessage
 */
export const NewRouteMessage = new NewRouteMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlightRouteMessage$Type extends MessageType<FlightRouteMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.FlightRouteMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flightUniqueId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "planningStage", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.PlanningStage", PlanningStage] },
            { no: 4, name: "routeType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.ObjectType", ObjectType] },
            { no: 5, name: "route", kind: "message", T: () => Trajectory },
            { no: 6, name: "preference", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<FlightRouteMessage>): FlightRouteMessage {
        const message = { xmlElementName: "", flightUniqueId: "", planningStage: 0, routeType: 0, preference: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlightRouteMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlightRouteMessage): FlightRouteMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string flightUniqueId */ 2:
                    message.flightUniqueId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.PlanningStage planningStage */ 3:
                    message.planningStage = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.ObjectType routeType */ 4:
                    message.routeType = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.Trajectory route */ 5:
                    message.route = Trajectory.internalBinaryRead(reader, reader.uint32(), options, message.route);
                    break;
                case /* float preference */ 6:
                    message.preference = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlightRouteMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string flightUniqueId = 2; */
        if (message.flightUniqueId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flightUniqueId);
        /* ProtobufAirTrafficSimulator.PlanningStage planningStage = 3; */
        if (message.planningStage !== 0)
            writer.tag(3, WireType.Varint).int32(message.planningStage);
        /* ProtobufAirTrafficSimulator.ObjectType routeType = 4; */
        if (message.routeType !== 0)
            writer.tag(4, WireType.Varint).int32(message.routeType);
        /* ProtobufAirTrafficSimulator.Trajectory route = 5; */
        if (message.route)
            Trajectory.internalBinaryWrite(message.route, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* float preference = 6; */
        if (message.preference !== 0)
            writer.tag(6, WireType.Bit32).float(message.preference);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.FlightRouteMessage
 */
export const FlightRouteMessage = new FlightRouteMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewStandManoeuvreMessage$Type extends MessageType<NewStandManoeuvreMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewStandManoeuvreMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "networkId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "routeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "routeType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.ObjectType", ObjectType] },
            { no: 5, name: "route", kind: "message", T: () => Trajectory },
            { no: 6, name: "speedCategory", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.SpeedCategory", SpeedCategory] },
            { no: 7, name: "stand", kind: "message", T: () => Position },
            { no: 8, name: "endPushback", kind: "message", T: () => Position },
            { no: 9, name: "bearing", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<NewStandManoeuvreMessage>): NewStandManoeuvreMessage {
        const message = { xmlElementName: "", networkId: "", routeId: "", routeType: 0, speedCategory: 0, bearing: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewStandManoeuvreMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewStandManoeuvreMessage): NewStandManoeuvreMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string networkId */ 2:
                    message.networkId = reader.string();
                    break;
                case /* string routeId */ 3:
                    message.routeId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.ObjectType routeType */ 4:
                    message.routeType = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.Trajectory route */ 5:
                    message.route = Trajectory.internalBinaryRead(reader, reader.uint32(), options, message.route);
                    break;
                case /* ProtobufAirTrafficSimulator.SpeedCategory speedCategory */ 6:
                    message.speedCategory = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.Position stand */ 7:
                    message.stand = Position.internalBinaryRead(reader, reader.uint32(), options, message.stand);
                    break;
                case /* ProtobufAirTrafficSimulator.Position endPushback */ 8:
                    message.endPushback = Position.internalBinaryRead(reader, reader.uint32(), options, message.endPushback);
                    break;
                case /* float bearing */ 9:
                    message.bearing = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewStandManoeuvreMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string networkId = 2; */
        if (message.networkId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.networkId);
        /* string routeId = 3; */
        if (message.routeId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.routeId);
        /* ProtobufAirTrafficSimulator.ObjectType routeType = 4; */
        if (message.routeType !== 0)
            writer.tag(4, WireType.Varint).int32(message.routeType);
        /* ProtobufAirTrafficSimulator.Trajectory route = 5; */
        if (message.route)
            Trajectory.internalBinaryWrite(message.route, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* ProtobufAirTrafficSimulator.SpeedCategory speedCategory = 6; */
        if (message.speedCategory !== 0)
            writer.tag(6, WireType.Varint).int32(message.speedCategory);
        /* ProtobufAirTrafficSimulator.Position stand = 7; */
        if (message.stand)
            Position.internalBinaryWrite(message.stand, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* ProtobufAirTrafficSimulator.Position endPushback = 8; */
        if (message.endPushback)
            Position.internalBinaryWrite(message.endPushback, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* float bearing = 9; */
        if (message.bearing !== 0)
            writer.tag(9, WireType.Bit32).float(message.bearing);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewStandManoeuvreMessage
 */
export const NewStandManoeuvreMessage = new NewStandManoeuvreMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewAirspaceMessage$Type extends MessageType<NewAirspaceMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewAirspaceMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "airspaceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "includedAirspaceVolumes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AirspaceVolumeReference },
            { no: 4, name: "area", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Position },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "objectType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.ObjectType", ObjectType] },
            { no: 7, name: "bottomFlightLevel", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "topFlightLevel", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<NewAirspaceMessage>): NewAirspaceMessage {
        const message = { xmlElementName: "", airspaceId: "", includedAirspaceVolumes: [], area: [], description: "", objectType: 0, bottomFlightLevel: 0, topFlightLevel: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewAirspaceMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewAirspaceMessage): NewAirspaceMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string airspaceId */ 2:
                    message.airspaceId = reader.string();
                    break;
                case /* repeated ProtobufAirTrafficSimulator.AirspaceVolumeReference includedAirspaceVolumes */ 3:
                    message.includedAirspaceVolumes.push(AirspaceVolumeReference.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ProtobufAirTrafficSimulator.Position area */ 4:
                    message.area.push(Position.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.ObjectType objectType */ 6:
                    message.objectType = reader.int32();
                    break;
                case /* int32 bottomFlightLevel */ 7:
                    message.bottomFlightLevel = reader.int32();
                    break;
                case /* int32 topFlightLevel */ 8:
                    message.topFlightLevel = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewAirspaceMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string airspaceId = 2; */
        if (message.airspaceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.airspaceId);
        /* repeated ProtobufAirTrafficSimulator.AirspaceVolumeReference includedAirspaceVolumes = 3; */
        for (let i = 0; i < message.includedAirspaceVolumes.length; i++)
            AirspaceVolumeReference.internalBinaryWrite(message.includedAirspaceVolumes[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated ProtobufAirTrafficSimulator.Position area = 4; */
        for (let i = 0; i < message.area.length; i++)
            Position.internalBinaryWrite(message.area[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* ProtobufAirTrafficSimulator.ObjectType objectType = 6; */
        if (message.objectType !== 0)
            writer.tag(6, WireType.Varint).int32(message.objectType);
        /* int32 bottomFlightLevel = 7; */
        if (message.bottomFlightLevel !== 0)
            writer.tag(7, WireType.Varint).int32(message.bottomFlightLevel);
        /* int32 topFlightLevel = 8; */
        if (message.topFlightLevel !== 0)
            writer.tag(8, WireType.Varint).int32(message.topFlightLevel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewAirspaceMessage
 */
export const NewAirspaceMessage = new NewAirspaceMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewSectorMessage$Type extends MessageType<NewSectorMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewSectorMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sectorId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "includedAirspaceVolumes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AirspaceVolumeReference },
            { no: 5, name: "area", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Position },
            { no: 6, name: "objectType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.ObjectType", ObjectType] }
        ]);
    }
    create(value?: PartialMessage<NewSectorMessage>): NewSectorMessage {
        const message = { xmlElementName: "", sectorId: "", description: "", includedAirspaceVolumes: [], area: [], objectType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewSectorMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewSectorMessage): NewSectorMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string sectorId */ 2:
                    message.sectorId = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* repeated ProtobufAirTrafficSimulator.AirspaceVolumeReference includedAirspaceVolumes */ 4:
                    message.includedAirspaceVolumes.push(AirspaceVolumeReference.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ProtobufAirTrafficSimulator.Position area */ 5:
                    message.area.push(Position.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* ProtobufAirTrafficSimulator.ObjectType objectType */ 6:
                    message.objectType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewSectorMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string sectorId = 2; */
        if (message.sectorId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sectorId);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* repeated ProtobufAirTrafficSimulator.AirspaceVolumeReference includedAirspaceVolumes = 4; */
        for (let i = 0; i < message.includedAirspaceVolumes.length; i++)
            AirspaceVolumeReference.internalBinaryWrite(message.includedAirspaceVolumes[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated ProtobufAirTrafficSimulator.Position area = 5; */
        for (let i = 0; i < message.area.length; i++)
            Position.internalBinaryWrite(message.area[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* ProtobufAirTrafficSimulator.ObjectType objectType = 6; */
        if (message.objectType !== 0)
            writer.tag(6, WireType.Varint).int32(message.objectType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewSectorMessage
 */
export const NewSectorMessage = new NewSectorMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewAirBlockMessage$Type extends MessageType<NewAirBlockMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewAirBlockMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "airBlockId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "area", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Position },
            { no: 5, name: "bottomFlightLevel", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "topFlightLevel", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "objectType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.ObjectType", ObjectType] }
        ]);
    }
    create(value?: PartialMessage<NewAirBlockMessage>): NewAirBlockMessage {
        const message = { xmlElementName: "", airBlockId: "", description: "", area: [], bottomFlightLevel: 0, topFlightLevel: 0, objectType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewAirBlockMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewAirBlockMessage): NewAirBlockMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string airBlockId */ 2:
                    message.airBlockId = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* repeated ProtobufAirTrafficSimulator.Position area */ 4:
                    message.area.push(Position.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 bottomFlightLevel */ 5:
                    message.bottomFlightLevel = reader.int32();
                    break;
                case /* int32 topFlightLevel */ 6:
                    message.topFlightLevel = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.ObjectType objectType */ 7:
                    message.objectType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewAirBlockMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string airBlockId = 2; */
        if (message.airBlockId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.airBlockId);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* repeated ProtobufAirTrafficSimulator.Position area = 4; */
        for (let i = 0; i < message.area.length; i++)
            Position.internalBinaryWrite(message.area[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 bottomFlightLevel = 5; */
        if (message.bottomFlightLevel !== 0)
            writer.tag(5, WireType.Varint).int32(message.bottomFlightLevel);
        /* int32 topFlightLevel = 6; */
        if (message.topFlightLevel !== 0)
            writer.tag(6, WireType.Varint).int32(message.topFlightLevel);
        /* ProtobufAirTrafficSimulator.ObjectType objectType = 7; */
        if (message.objectType !== 0)
            writer.tag(7, WireType.Varint).int32(message.objectType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewAirBlockMessage
 */
export const NewAirBlockMessage = new NewAirBlockMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewAirspaceConfigurationMessage$Type extends MessageType<NewAirspaceConfigurationMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewAirspaceConfigurationMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "configurationId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "includedAirspaceVolumes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AirspaceVolumeReference },
            { no: 6, name: "area", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Position },
            { no: 7, name: "objectType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.ObjectType", ObjectType] }
        ]);
    }
    create(value?: PartialMessage<NewAirspaceConfigurationMessage>): NewAirspaceConfigurationMessage {
        const message = { xmlElementName: "", configurationId: "", description: "", includedAirspaceVolumes: [], area: [], objectType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewAirspaceConfigurationMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewAirspaceConfigurationMessage): NewAirspaceConfigurationMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string configurationId */ 2:
                    message.configurationId = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* repeated ProtobufAirTrafficSimulator.AirspaceVolumeReference includedAirspaceVolumes */ 5:
                    message.includedAirspaceVolumes.push(AirspaceVolumeReference.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ProtobufAirTrafficSimulator.Position area */ 6:
                    message.area.push(Position.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* ProtobufAirTrafficSimulator.ObjectType objectType */ 7:
                    message.objectType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewAirspaceConfigurationMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string configurationId = 2; */
        if (message.configurationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.configurationId);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* repeated ProtobufAirTrafficSimulator.AirspaceVolumeReference includedAirspaceVolumes = 5; */
        for (let i = 0; i < message.includedAirspaceVolumes.length; i++)
            AirspaceVolumeReference.internalBinaryWrite(message.includedAirspaceVolumes[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated ProtobufAirTrafficSimulator.Position area = 6; */
        for (let i = 0; i < message.area.length; i++)
            Position.internalBinaryWrite(message.area[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* ProtobufAirTrafficSimulator.ObjectType objectType = 7; */
        if (message.objectType !== 0)
            writer.tag(7, WireType.Varint).int32(message.objectType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewAirspaceConfigurationMessage
 */
export const NewAirspaceConfigurationMessage = new NewAirspaceConfigurationMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeInterval$Type extends MessageType<TimeInterval> {
    constructor() {
        super("ProtobufAirTrafficSimulator.TimeInterval", [
            { no: 1, name: "starttime", kind: "message", T: () => Timestamp },
            { no: 2, name: "endttime", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<TimeInterval>): TimeInterval {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TimeInterval>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeInterval): TimeInterval {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp starttime */ 1:
                    message.starttime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.starttime);
                    break;
                case /* google.protobuf.Timestamp endttime */ 2:
                    message.endttime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endttime);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeInterval, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp starttime = 1; */
        if (message.starttime)
            Timestamp.internalBinaryWrite(message.starttime, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp endttime = 2; */
        if (message.endttime)
            Timestamp.internalBinaryWrite(message.endttime, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.TimeInterval
 */
export const TimeInterval = new TimeInterval$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AirspaceAvailabilityMessage$Type extends MessageType<AirspaceAvailabilityMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.AirspaceAvailabilityMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "airspaceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "startTime", kind: "message", T: () => Timestamp },
            { no: 4, name: "endTime", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<AirspaceAvailabilityMessage>): AirspaceAvailabilityMessage {
        const message = { xmlElementName: "", airspaceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AirspaceAvailabilityMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AirspaceAvailabilityMessage): AirspaceAvailabilityMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string airspaceId */ 2:
                    message.airspaceId = reader.string();
                    break;
                case /* google.protobuf.Timestamp startTime */ 3:
                    message.startTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startTime);
                    break;
                case /* google.protobuf.Timestamp endTime */ 4:
                    message.endTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endTime);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AirspaceAvailabilityMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string airspaceId = 2; */
        if (message.airspaceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.airspaceId);
        /* google.protobuf.Timestamp startTime = 3; */
        if (message.startTime)
            Timestamp.internalBinaryWrite(message.startTime, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp endTime = 4; */
        if (message.endTime)
            Timestamp.internalBinaryWrite(message.endTime, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.AirspaceAvailabilityMessage
 */
export const AirspaceAvailabilityMessage = new AirspaceAvailabilityMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AvailabilityIntervalsMessage$Type extends MessageType<AvailabilityIntervalsMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.AvailabilityIntervalsMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "objectId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timeIntervals", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimeInterval }
        ]);
    }
    create(value?: PartialMessage<AvailabilityIntervalsMessage>): AvailabilityIntervalsMessage {
        const message = { xmlElementName: "", objectId: "", timeIntervals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AvailabilityIntervalsMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AvailabilityIntervalsMessage): AvailabilityIntervalsMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string objectId */ 2:
                    message.objectId = reader.string();
                    break;
                case /* repeated ProtobufAirTrafficSimulator.TimeInterval timeIntervals */ 3:
                    message.timeIntervals.push(TimeInterval.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AvailabilityIntervalsMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string objectId = 2; */
        if (message.objectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.objectId);
        /* repeated ProtobufAirTrafficSimulator.TimeInterval timeIntervals = 3; */
        for (let i = 0; i < message.timeIntervals.length; i++)
            TimeInterval.internalBinaryWrite(message.timeIntervals[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.AvailabilityIntervalsMessage
 */
export const AvailabilityIntervalsMessage = new AvailabilityIntervalsMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewSegmentMessage$Type extends MessageType<NewSegmentMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewSegmentMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "networkId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "segmentId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "segmentType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.NetworkResourceType", NetworkResourceType] },
            { no: 5, name: "points", kind: "message", T: () => Trajectory },
            { no: 6, name: "curvature", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "direction", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.SegmentDirection", SegmentDirection] }
        ]);
    }
    create(value?: PartialMessage<NewSegmentMessage>): NewSegmentMessage {
        const message = { xmlElementName: "", networkId: "", segmentId: "", segmentType: 0, curvature: 0, direction: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewSegmentMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewSegmentMessage): NewSegmentMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string networkId */ 2:
                    message.networkId = reader.string();
                    break;
                case /* string segmentId */ 3:
                    message.segmentId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.NetworkResourceType segmentType */ 4:
                    message.segmentType = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.Trajectory points */ 5:
                    message.points = Trajectory.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                case /* float curvature */ 6:
                    message.curvature = reader.float();
                    break;
                case /* ProtobufAirTrafficSimulator.SegmentDirection direction */ 7:
                    message.direction = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewSegmentMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string networkId = 2; */
        if (message.networkId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.networkId);
        /* string segmentId = 3; */
        if (message.segmentId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.segmentId);
        /* ProtobufAirTrafficSimulator.NetworkResourceType segmentType = 4; */
        if (message.segmentType !== 0)
            writer.tag(4, WireType.Varint).int32(message.segmentType);
        /* ProtobufAirTrafficSimulator.Trajectory points = 5; */
        if (message.points)
            Trajectory.internalBinaryWrite(message.points, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* float curvature = 6; */
        if (message.curvature !== 0)
            writer.tag(6, WireType.Bit32).float(message.curvature);
        /* ProtobufAirTrafficSimulator.SegmentDirection direction = 7; */
        if (message.direction !== 0)
            writer.tag(7, WireType.Varint).int32(message.direction);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewSegmentMessage
 */
export const NewSegmentMessage = new NewSegmentMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewWaypointMessage$Type extends MessageType<NewWaypointMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewWaypointMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "networkId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "objectId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "objectType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.ObjectType", ObjectType] },
            { no: 5, name: "waypointType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.WaypointType", WaypointType] },
            { no: 6, name: "points", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NewWaypointMessage>): NewWaypointMessage {
        const message = { xmlElementName: "", networkId: "", objectId: "", objectType: 0, waypointType: 0, points: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewWaypointMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewWaypointMessage): NewWaypointMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string networkId */ 2:
                    message.networkId = reader.string();
                    break;
                case /* string objectId */ 3:
                    message.objectId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.ObjectType objectType */ 4:
                    message.objectType = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.WaypointType waypointType */ 5:
                    message.waypointType = reader.int32();
                    break;
                case /* repeated string points */ 6:
                    message.points.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewWaypointMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string networkId = 2; */
        if (message.networkId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.networkId);
        /* string objectId = 3; */
        if (message.objectId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.objectId);
        /* ProtobufAirTrafficSimulator.ObjectType objectType = 4; */
        if (message.objectType !== 0)
            writer.tag(4, WireType.Varint).int32(message.objectType);
        /* ProtobufAirTrafficSimulator.WaypointType waypointType = 5; */
        if (message.waypointType !== 0)
            writer.tag(5, WireType.Varint).int32(message.waypointType);
        /* repeated string points = 6; */
        for (let i = 0; i < message.points.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.points[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewWaypointMessage
 */
export const NewWaypointMessage = new NewWaypointMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewPointMessage$Type extends MessageType<NewPointMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewPointMessage", [
            { no: 1, name: "xmlElementName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "networkId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "nodeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "nodeType", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.ObjectType", ObjectType] },
            { no: 5, name: "position", kind: "message", T: () => Position }
        ]);
    }
    create(value?: PartialMessage<NewPointMessage>): NewPointMessage {
        const message = { xmlElementName: "", networkId: "", nodeId: "", nodeType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewPointMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewPointMessage): NewPointMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string xmlElementName */ 1:
                    message.xmlElementName = reader.string();
                    break;
                case /* string networkId */ 2:
                    message.networkId = reader.string();
                    break;
                case /* string nodeId */ 3:
                    message.nodeId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.ObjectType nodeType */ 4:
                    message.nodeType = reader.int32();
                    break;
                case /* ProtobufAirTrafficSimulator.Position position */ 5:
                    message.position = Position.internalBinaryRead(reader, reader.uint32(), options, message.position);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewPointMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string xmlElementName = 1; */
        if (message.xmlElementName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.xmlElementName);
        /* string networkId = 2; */
        if (message.networkId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.networkId);
        /* string nodeId = 3; */
        if (message.nodeId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.nodeId);
        /* ProtobufAirTrafficSimulator.ObjectType nodeType = 4; */
        if (message.nodeType !== 0)
            writer.tag(4, WireType.Varint).int32(message.nodeType);
        /* ProtobufAirTrafficSimulator.Position position = 5; */
        if (message.position)
            Position.internalBinaryWrite(message.position, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewPointMessage
 */
export const NewPointMessage = new NewPointMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoleConfigurationMessage$Type extends MessageType<RoleConfigurationMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.RoleConfigurationMessage", [
            { no: 1, name: "roleName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sectorToControl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "actualIdOfSectorToControl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "acceptedFlights", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tentativeFlights", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RoleConfigurationMessage>): RoleConfigurationMessage {
        const message = { roleName: "", sectorToControl: "", actualIdOfSectorToControl: "", acceptedFlights: [], tentativeFlights: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RoleConfigurationMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoleConfigurationMessage): RoleConfigurationMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string roleName */ 1:
                    message.roleName = reader.string();
                    break;
                case /* string sectorToControl */ 2:
                    message.sectorToControl = reader.string();
                    break;
                case /* string actualIdOfSectorToControl */ 3:
                    message.actualIdOfSectorToControl = reader.string();
                    break;
                case /* repeated string acceptedFlights */ 4:
                    message.acceptedFlights.push(reader.string());
                    break;
                case /* repeated string tentativeFlights */ 5:
                    message.tentativeFlights.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoleConfigurationMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string roleName = 1; */
        if (message.roleName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.roleName);
        /* string sectorToControl = 2; */
        if (message.sectorToControl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sectorToControl);
        /* string actualIdOfSectorToControl = 3; */
        if (message.actualIdOfSectorToControl !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.actualIdOfSectorToControl);
        /* repeated string acceptedFlights = 4; */
        for (let i = 0; i < message.acceptedFlights.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.acceptedFlights[i]);
        /* repeated string tentativeFlights = 5; */
        for (let i = 0; i < message.tentativeFlights.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.tentativeFlights[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.RoleConfigurationMessage
 */
export const RoleConfigurationMessage = new RoleConfigurationMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CurrentAirspaceConfigurationMessage$Type extends MessageType<CurrentAirspaceConfigurationMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.CurrentAirspaceConfigurationMessage", [
            { no: 1, name: "CurrentAirspaceConfiguration", kind: "scalar", jsonName: "CurrentAirspaceConfiguration", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CurrentAirspaceConfigurationMessage>): CurrentAirspaceConfigurationMessage {
        const message = { currentAirspaceConfiguration: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CurrentAirspaceConfigurationMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CurrentAirspaceConfigurationMessage): CurrentAirspaceConfigurationMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string CurrentAirspaceConfiguration = 1 [json_name = "CurrentAirspaceConfiguration"];*/ 1:
                    message.currentAirspaceConfiguration = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CurrentAirspaceConfigurationMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string CurrentAirspaceConfiguration = 1 [json_name = "CurrentAirspaceConfiguration"]; */
        if (message.currentAirspaceConfiguration !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.currentAirspaceConfiguration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.CurrentAirspaceConfigurationMessage
 */
export const CurrentAirspaceConfigurationMessage = new CurrentAirspaceConfigurationMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TesselatedAirspaceVolumeMessage$Type extends MessageType<TesselatedAirspaceVolumeMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.TesselatedAirspaceVolumeMessage", [
            { no: 1, name: "airspaceVolumeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "belongsToAirspaceConfigId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "numberOfVolumesInConfig", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "tesselatedSurfaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TesselatedSectorSurface }
        ]);
    }
    create(value?: PartialMessage<TesselatedAirspaceVolumeMessage>): TesselatedAirspaceVolumeMessage {
        const message = { airspaceVolumeId: "", belongsToAirspaceConfigId: "", numberOfVolumesInConfig: 0, tesselatedSurfaces: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TesselatedAirspaceVolumeMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TesselatedAirspaceVolumeMessage): TesselatedAirspaceVolumeMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string airspaceVolumeId */ 1:
                    message.airspaceVolumeId = reader.string();
                    break;
                case /* string belongsToAirspaceConfigId */ 2:
                    message.belongsToAirspaceConfigId = reader.string();
                    break;
                case /* int32 numberOfVolumesInConfig */ 3:
                    message.numberOfVolumesInConfig = reader.int32();
                    break;
                case /* repeated ProtobufAirTrafficSimulator.TesselatedSectorSurface tesselatedSurfaces */ 4:
                    message.tesselatedSurfaces.push(TesselatedSectorSurface.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TesselatedAirspaceVolumeMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string airspaceVolumeId = 1; */
        if (message.airspaceVolumeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.airspaceVolumeId);
        /* string belongsToAirspaceConfigId = 2; */
        if (message.belongsToAirspaceConfigId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.belongsToAirspaceConfigId);
        /* int32 numberOfVolumesInConfig = 3; */
        if (message.numberOfVolumesInConfig !== 0)
            writer.tag(3, WireType.Varint).int32(message.numberOfVolumesInConfig);
        /* repeated ProtobufAirTrafficSimulator.TesselatedSectorSurface tesselatedSurfaces = 4; */
        for (let i = 0; i < message.tesselatedSurfaces.length; i++)
            TesselatedSectorSurface.internalBinaryWrite(message.tesselatedSurfaces[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.TesselatedAirspaceVolumeMessage
 */
export const TesselatedAirspaceVolumeMessage = new TesselatedAirspaceVolumeMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewAirspaceVolumeFlightListMessage$Type extends MessageType<NewAirspaceVolumeFlightListMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.NewAirspaceVolumeFlightListMessage", [
            { no: 1, name: "airspaceVolumeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flightsInVolume", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NewAirspaceVolumeFlightListMessage>): NewAirspaceVolumeFlightListMessage {
        const message = { airspaceVolumeId: "", flightsInVolume: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NewAirspaceVolumeFlightListMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewAirspaceVolumeFlightListMessage): NewAirspaceVolumeFlightListMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string airspaceVolumeId */ 1:
                    message.airspaceVolumeId = reader.string();
                    break;
                case /* repeated string flightsInVolume */ 2:
                    message.flightsInVolume.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewAirspaceVolumeFlightListMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string airspaceVolumeId = 1; */
        if (message.airspaceVolumeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.airspaceVolumeId);
        /* repeated string flightsInVolume = 2; */
        for (let i = 0; i < message.flightsInVolume.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.flightsInVolume[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.NewAirspaceVolumeFlightListMessage
 */
export const NewAirspaceVolumeFlightListMessage = new NewAirspaceVolumeFlightListMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AirTrafficControllerAssignmentMessage$Type extends MessageType<AirTrafficControllerAssignmentMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.AirTrafficControllerAssignmentMessage", [
            { no: 1, name: "airTrafficControllerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sectorIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AirTrafficControllerAssignmentMessage>): AirTrafficControllerAssignmentMessage {
        const message = { airTrafficControllerId: "", sectorIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AirTrafficControllerAssignmentMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AirTrafficControllerAssignmentMessage): AirTrafficControllerAssignmentMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string airTrafficControllerId */ 1:
                    message.airTrafficControllerId = reader.string();
                    break;
                case /* repeated string sectorIds */ 2:
                    message.sectorIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AirTrafficControllerAssignmentMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string airTrafficControllerId = 1; */
        if (message.airTrafficControllerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.airTrafficControllerId);
        /* repeated string sectorIds = 2; */
        for (let i = 0; i < message.sectorIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.sectorIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.AirTrafficControllerAssignmentMessage
 */
export const AirTrafficControllerAssignmentMessage = new AirTrafficControllerAssignmentMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlightEnteringAirspaceMessage$Type extends MessageType<FlightEnteringAirspaceMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.FlightEnteringAirspaceMessage", [
            { no: 1, name: "flightUniqueId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sectorId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "entryPosition", kind: "message", T: () => Position4D },
            { no: 4, name: "exitPosition", kind: "message", T: () => Position4D },
            { no: 5, name: "entryWaypointId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "exitWaypointId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "planningStage", kind: "enum", T: () => ["ProtobufAirTrafficSimulator.PlanningStage", PlanningStage] }
        ]);
    }
    create(value?: PartialMessage<FlightEnteringAirspaceMessage>): FlightEnteringAirspaceMessage {
        const message = { flightUniqueId: "", sectorId: "", entryWaypointId: "", exitWaypointId: "", planningStage: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlightEnteringAirspaceMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlightEnteringAirspaceMessage): FlightEnteringAirspaceMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string flightUniqueId */ 1:
                    message.flightUniqueId = reader.string();
                    break;
                case /* string sectorId */ 2:
                    message.sectorId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.Position4D entryPosition */ 3:
                    message.entryPosition = Position4D.internalBinaryRead(reader, reader.uint32(), options, message.entryPosition);
                    break;
                case /* ProtobufAirTrafficSimulator.Position4D exitPosition */ 4:
                    message.exitPosition = Position4D.internalBinaryRead(reader, reader.uint32(), options, message.exitPosition);
                    break;
                case /* string entryWaypointId */ 5:
                    message.entryWaypointId = reader.string();
                    break;
                case /* string exitWaypointId */ 6:
                    message.exitWaypointId = reader.string();
                    break;
                case /* ProtobufAirTrafficSimulator.PlanningStage planningStage */ 7:
                    message.planningStage = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlightEnteringAirspaceMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string flightUniqueId = 1; */
        if (message.flightUniqueId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.flightUniqueId);
        /* string sectorId = 2; */
        if (message.sectorId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sectorId);
        /* ProtobufAirTrafficSimulator.Position4D entryPosition = 3; */
        if (message.entryPosition)
            Position4D.internalBinaryWrite(message.entryPosition, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* ProtobufAirTrafficSimulator.Position4D exitPosition = 4; */
        if (message.exitPosition)
            Position4D.internalBinaryWrite(message.exitPosition, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string entryWaypointId = 5; */
        if (message.entryWaypointId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.entryWaypointId);
        /* string exitWaypointId = 6; */
        if (message.exitWaypointId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.exitWaypointId);
        /* ProtobufAirTrafficSimulator.PlanningStage planningStage = 7; */
        if (message.planningStage !== 0)
            writer.tag(7, WireType.Varint).int32(message.planningStage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.FlightEnteringAirspaceMessage
 */
export const FlightEnteringAirspaceMessage = new FlightEnteringAirspaceMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddAcceptedFlightMessage$Type extends MessageType<AddAcceptedFlightMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.AddAcceptedFlightMessage", [
            { no: 1, name: "fromControllableAirspaceVolume", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "toControllableAirspaceVolume", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "flightId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timeStamp", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<AddAcceptedFlightMessage>): AddAcceptedFlightMessage {
        const message = { fromControllableAirspaceVolume: "", toControllableAirspaceVolume: "", flightId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddAcceptedFlightMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddAcceptedFlightMessage): AddAcceptedFlightMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fromControllableAirspaceVolume */ 1:
                    message.fromControllableAirspaceVolume = reader.string();
                    break;
                case /* string toControllableAirspaceVolume */ 2:
                    message.toControllableAirspaceVolume = reader.string();
                    break;
                case /* string flightId */ 3:
                    message.flightId = reader.string();
                    break;
                case /* google.protobuf.Timestamp timeStamp */ 4:
                    message.timeStamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timeStamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddAcceptedFlightMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fromControllableAirspaceVolume = 1; */
        if (message.fromControllableAirspaceVolume !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fromControllableAirspaceVolume);
        /* string toControllableAirspaceVolume = 2; */
        if (message.toControllableAirspaceVolume !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.toControllableAirspaceVolume);
        /* string flightId = 3; */
        if (message.flightId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.flightId);
        /* google.protobuf.Timestamp timeStamp = 4; */
        if (message.timeStamp)
            Timestamp.internalBinaryWrite(message.timeStamp, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.AddAcceptedFlightMessage
 */
export const AddAcceptedFlightMessage = new AddAcceptedFlightMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddTentativeFlightMessage$Type extends MessageType<AddTentativeFlightMessage> {
    constructor() {
        super("ProtobufAirTrafficSimulator.AddTentativeFlightMessage", [
            { no: 1, name: "fromControllableAirspaceVolume", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "toControllableAirspaceVolume", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "flightId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "timeStamp", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<AddTentativeFlightMessage>): AddTentativeFlightMessage {
        const message = { fromControllableAirspaceVolume: "", toControllableAirspaceVolume: "", flightId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddTentativeFlightMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddTentativeFlightMessage): AddTentativeFlightMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fromControllableAirspaceVolume */ 1:
                    message.fromControllableAirspaceVolume = reader.string();
                    break;
                case /* string toControllableAirspaceVolume */ 2:
                    message.toControllableAirspaceVolume = reader.string();
                    break;
                case /* string flightId */ 3:
                    message.flightId = reader.string();
                    break;
                case /* google.protobuf.Timestamp timeStamp */ 4:
                    message.timeStamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timeStamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddTentativeFlightMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fromControllableAirspaceVolume = 1; */
        if (message.fromControllableAirspaceVolume !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fromControllableAirspaceVolume);
        /* string toControllableAirspaceVolume = 2; */
        if (message.toControllableAirspaceVolume !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.toControllableAirspaceVolume);
        /* string flightId = 3; */
        if (message.flightId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.flightId);
        /* google.protobuf.Timestamp timeStamp = 4; */
        if (message.timeStamp)
            Timestamp.internalBinaryWrite(message.timeStamp, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ProtobufAirTrafficSimulator.AddTentativeFlightMessage
 */
export const AddTentativeFlightMessage = new AddTentativeFlightMessage$Type();
